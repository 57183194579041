import Header from './Header'
import Sidebar from './Sidebar'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '@/store'
import axios from '../../../shared/config/axios-interceptor'
import { RootState } from '@/reducers'
import Nav from './Nav'
import { ITransaction } from '@/shared/model/transaction.model'
import dayjs from 'dayjs'
import { formatVND } from '@/shared/utils/ultils'
import {
  mapResponseStatusToColorTransaction,
  mapResponseStatusTransaction,
} from '@/shared/enumeration/ResponseStatus'

const LichSuNapTien = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.authentication)

  const [data, setData] = useState<ITransaction[] | []>([])

  const getData = async () => {
    try {
      const response = await axios.get(
        '/transaction?type=1&sortOrder=DESC&sortBy=id&limit=20&userId=' +
          user?.id,
      )
      setData(response.data.data)
    } catch (error) {
      setData([])
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="container-web page-rut-tien-web page-lich-su-rut-tien-web">
      <Header />
      <div className="content-header d-flex gap-3">
        <Nav />

        <div className="header-bottom m-0 w-100">
          <div
            className="top d-flex px-3  align-items-center fw-bold"
            style={{ height: 45 }}
          >
            Lịch sử nạp tiền
          </div>

          <div className="top d-flex mx-3">
            <div className="w-25">Số thứ tự</div>
            <div className="w-25">Số tiền</div>
            <div className="w-25">Ngày rút</div>
            <div className="w-25">Trạng thái</div>
          </div>

          <div className="mx-3">
            {data.length ? (
              data?.map((item, index) => {
                return (
                  <div className="d-flex item-content" key={index}>
                    <div className="w-25">{index + 1}</div>
                    <div className="w-25">{formatVND(item.money)}</div>
                    <div className="w-25">
                      {dayjs(item.createdDate).format('DD/MM/YYYY HH:mm:ss')}
                    </div>
                    <div
                      className={
                        'w-25 text-' +
                        mapResponseStatusToColorTransaction[item.status]
                      }
                    >
                      {mapResponseStatusTransaction[item.status]}
                    </div>
                  </div>
                )
              })
            ) : (
              <h6 style={{ textAlign: 'center', color: '#888', marginTop: 20 }}>
                Chưa có lệnh nạp tiền nào
              </h6>
            )}
          </div>
        </div>
      </div>
      <div className="page-body-web">
        {/* <Sidebar /> */}
      </div>
    </div>
  )
}

export default LichSuNapTien
