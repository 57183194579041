import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import HomeWap from './HomeWap';
import Game from './Game';
import Account from './Account';
import AddBank from './AddBank';
import History from './History';
import Withdraw from './Withdraw';
import HistoryOrder from './HistoryOrder';
import HistoryWithdraw from './HistoryWithdraw';
import HistoryDeposit from './HistoryDeposit';
import ChangePassword from './ChangePassword';
import Info from './Info';
import { AccountInfo } from './AccountInfo';
import Message from './Message';
import { UuDaiWap } from './UuDaiWap';
import { MaGioiThieu } from './MaGioiThieu';
import MatKhauRutTien from './MatKhauRutTien';


const WebManagementLayout: RouteObject[] = [
  {
    path: '/',
    element: <HomeWap />,
  },
  {
    path: '/account',
    element: <Account />,
  },
  // {
  //   path: '/history',
  //   element: <History />,
  // },
  {
    path: '/add-bank',
    element: <AddBank />,
  },
  {
    path: '/withdraw',
    element: <Withdraw />,
  },
  {
    path: '/cskh',
    element: <Withdraw />,
  },
  {
    path: '/history-order',
    element: <HistoryOrder />,
  },
  {
    path: '/history-withdraw',
    element: <HistoryWithdraw />,
  },
  {
    path: '/history-deposit',
    element: <HistoryDeposit />,
  },

  {
    path: '/change-password',
    element: <ChangePassword />,
  },

  {
    path: '/message',
    element: <Message />,
  },

  {
    path: '/game/:id',
    element: <Game />,
  },
  {
    path: '/info',
    element: <Info />,
  },
  {
    path: '/account-info',
    element: <AccountInfo />,
  },
  {
    path: '/uudai',
    element: <UuDaiWap />,
  },
  {
    path: '/magioithieu',
    element: <MaGioiThieu />,
  },
  {
    path: '/mat-khau-rut-tien',
    element: <MatKhauRutTien />,
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const WapManagementRoutes = () => useRoutes(WebManagementLayout);
export default WapManagementRoutes;
