import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../shared/config/axios-interceptor';
import { IUser } from '../../../shared/model/user.model';
import * as axiosAlias from 'axios';
export interface ILoginForm {
  username: string;
  password: string;
  repassword?: string;
  agentId?: string;
  passsWithdraw?: string;
}

export interface IForgotPassword {
  email: string;
}

export interface IChangePassword {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const prefixAuth = 'auth';

export const login = createAsyncThunk(`auth-login`, async (body: ILoginForm, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefixAuth}/login`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const register = createAsyncThunk(`auth-register`, async (body: ILoginForm, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefixAuth}/signup`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const getIp = async () => {
  return await axiosAlias.default.get('https://api.ipify.org?format=json')
}

export const getProfile = createAsyncThunk(`get-profile`, async (_, thunkAPI) => {
  try {
    const ip = await getIp()
    const { data } = await axios.get(`${prefixAuth}/profile?ip=${ip.data.ip}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const forgotPassword = createAsyncThunk(`forgot-password`, async (body: IForgotPassword, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefixAuth}/forgot-password`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const changePassword = createAsyncThunk(`change-password`, async (body: IChangePassword, thunkAPI) => {
  try {
    const { data } = await axios.put(`${prefixAuth}/change-password`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateProfile = createAsyncThunk(`update-profile`, async (body: IUser, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put(`profile/${id}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
