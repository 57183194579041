import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../shared/config/axios-interceptor';
import { IGameSidebar, INewGameSidebar } from '@/shared/model/gameSidebar.model';
import { ResponseStatus } from '@/shared/enumeration/ResponseStatus';
import { IParams } from '@/shared/shared-interfaces';

const prefix = 'game-sidebar';
// const history = 'revision';

export interface IGameSidebarParams extends IParams {
  status?: ResponseStatus;
  gameSidebarId?: number;
  // projectId?: number; // Lấy tất cả user không phải là nhân viên của dự án được truyền vào
}

export const getEntities: any = createAsyncThunk(`get-all-${prefix}`, async (fields: IGameSidebarParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IGameSidebar[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IGameSidebar>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity: any = createAsyncThunk(`create-one-${prefix}`, async (body: INewGameSidebar, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity: any = createAsyncThunk(`update-one-${prefix}`, async (body: IGameSidebar, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<IGameSidebar>(`${prefix}/${id}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IUserDel {
  id: number[];
  status: ResponseStatus;
}

export const updateDeleteEntity: any = createAsyncThunk(
  `delete-status-${prefix}`,
  async (requestBody: IUserDel, thunkAPI) => {
    try {
      await axios.delete<IGameSidebar>(`${prefix}/` + requestBody.id);
      return requestBody.id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


export const getCurrentDraw = createAsyncThunk(`get-current-${prefix}`, async (id: number, thunkAPI) => {
  try {
    return await axios.get<IGameSidebar[]>(`${prefix}/get-current/${id}`);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});



export interface IProcedureStepUpdate {
  id: number;
  index: number;
}

export interface IDataProcedureStepUpdate {
  data: IProcedureStepUpdate[]
}

export const updateGameSidebarEntities: any = createAsyncThunk (`update-step-${prefix}`, async (result: IDataProcedureStepUpdate, thunkAPI) => {
  try {
    const {data} =  await axios.post<IGameSidebar>(`${prefix}/index`, result);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
})