import ArrowBottomIcon from '@/components/shared/icons/web/ArrowBotomIcon'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import sidebar0 from '../../../assets/img/sidebar0.png'
import sidebar1 from '../../../assets/img/sidebar1.png'
import sidebar2 from '../../../assets/img/sidebar2.png'
import sidebar3 from '../../../assets/img/sidebar3.png'
import sidebar4 from '../../../assets/img/sidebar4.png'
import sidebar5 from '../../../assets/img/sidebar5.png'
import sidebar6 from '../../../assets/img/sidebar6.png'
import sidebar7 from '../../../assets/img/sidebar7.png'
import sidebar8 from '../../../assets/img/sidebar8.png'
import sidebar9 from '../../../assets/img/sidebar9.png'
import sidebar10 from '../../../assets/img/sidebar10.png'
import sidebar11 from '../../../assets/img/sidebar11.png'
import sidebar12 from '../../../assets/img/sidebar12.png'
import sidebar13 from '../../../assets/img/cskh.png'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { AppDispatch } from '@/store'
import { getEntities } from '../GameTypeManagement/gameTypeManagement.api'
import { gameTypeSelectors } from '../GameTypeManagement/gameTypeManagement.reducer'

const Sidebar = () => {
  const dispatch = useDispatch<AppDispatch>()

  const [showChildSidebar, setShowChildSidebar] = useState(0)

  const gameTypes = useSelector(gameTypeSelectors.selectAll)

  const { initialState } = useSelector(
    (state: RootState) => state.gameTypeReducer,
  )
  const { filterState } = initialState

  useEffect(() => {
    dispatch(
      getEntities({
        ...filterState,
        page: 1,
        limit: 100,
        sortOrder: 'ASC',
        sortBy: 'order',
        status: 'ACTIVE',
      }),
    )
  }, [])

  return (
    <>
      <div className="sidebar-web">
        {/* {sidebar.map((item, index) => {
          return (
            <div className="parent-item" key={index}>
              <span
                className="parent-title d-flex justify-content-between"
                onClick={() => setShowChildSidebar(index)}
              >
                <div className="d-flex align-items-center gap-2">
                  <img width={17} src={item.image} alt="" />
                  {item.name}
                </div>
                <ArrowBottomIcon width={12} fill="#fff" />
              </span>

              <div className="child-item">
                {showChildSidebar === index &&
                  item.children.map((child, index) => {
                    return (
                      <NavLink key={index} to={child.path} className="item">
                        {child.name}
                      </NavLink>
                    )
                  })}
              </div>
            </div>
          )
        })} */}

        <div className="parent-item">
          <span className="parent-title d-flex justify-content-between">
            <div className="d-flex align-items-center gap-2">
              <img width={17} src={sidebar0} alt="" />
              HẠNG MỤC
            </div>
          </span>
          <div className="child-item"></div>
        </div>

        {gameTypes.map((item, index) => {
          return (
            <div className="parent-item" key={index}>
              <span
                className="parent-title d-flex justify-content-between"
                onClick={() => setShowChildSidebar(index)}
              >
                <div className="d-flex align-items-center gap-2">
                  <img width={17} src={item.imageDesktop} alt="" />
                  {item.name}
                </div>
                <ArrowBottomIcon width={12} fill="#fff" />
              </span>
              <div className="child-item">
                {showChildSidebar === index &&
                  item.game.map((child, index) => {
                    return (
                      <NavLink
                        key={index}
                        to={`/game/${child.id}`}
                        className="item"
                      >
                        {child.name}
                      </NavLink>
                    )
                  })}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Sidebar
