import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import BankManagement from './BankManagement';


const BankManagementLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      <BankManagement />
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const BanksManagementRoutes = () => useRoutes(BankManagementLayout);
export default BanksManagementRoutes;
