import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../shared/config/axios-interceptor';
import { ResponseStatus } from '../../../shared/enumeration/ResponseStatus';
import { IParams } from '../../../shared/shared-interfaces';
import { SystemRole } from '@/shared/enumeration/role';
import { IGameType, INewGameType } from '@/shared/model/gameType.model';

const prefix = 'game-type';
// const history = 'revision';

export interface IGameTypeParams extends IParams {
  status?: ResponseStatus;
  projectId?: number; // Lấy tất cả gameType không phải là nhân viên của dự án được truyền vào
  keyword?: string; // Lấy tất cả gameType không phải là nhân viên của dự án được truyền vào
  role?: SystemRole; // Lấy tất cả gameType không phải là nhân viên của dự án được truyền vào
}

export const getEntities: any = createAsyncThunk(`get-all-${prefix}`, async (fields: IGameTypeParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IGameType[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IGameType>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewGameType, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IGameType, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<IGameType>(`${prefix}/${id}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IGameTypeDel {
  id: number[];
  status: ResponseStatus;
}

export const updateDeleteEntity = createAsyncThunk(
  `delete-status-${prefix}`,
  async (requestBody: IGameTypeDel, thunkAPI) => {
    try {
      await axios.post<IGameType>(`${prefix}/status`, requestBody);
      return requestBody.id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
