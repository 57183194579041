import ConfirmDeleteModal from '@/components/shared/ConfirmDeleteModal/ConfirmDeleteModal'
import Edit02Icon from '@/components/shared/icons/Edit02Icon'
import { ToastSuccess } from '@/components/shared/toast/Toast'
import {
  CCol,
  CFormLabel,
  CFormSelect,
  CFormSwitch,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSmartTable,
} from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../reducers'
import { ResponseStatus } from '../../../shared/enumeration/ResponseStatus'
import { IUser } from '../../../shared/model/user.model'
import { createIndexes } from '../../../shared/utils/ultils'
import { AppDispatch } from '../../../store'
import CustomTableFooter from '../../shared/CustomTableFooter/CustomTableFooter'
import {
  getEntities,
  removeEntity,
  updateEntity,
} from './gameNameManagement.api'
import {
  fetching,
  setFilterState,
  gameNameSelectors,
  resetEntity,
} from './gameNameManagement.reducer'

import { getEntities as getGameTypeEntities } from '../GameTypeManagement/gameTypeManagement.api'

// import {
//   fetching,
//   setFilterState,
//   gameNameSelectors,
//   resetEntity,
// } from '../GameTypeManagement/gameTypeManagement.reducer'

import { IGameName } from '@/shared/model/gameName.model'
import { gameTypeSelectors } from '../GameTypeManagement/gameTypeManagement.reducer'
import { useRouter } from '@/shared/utils/hooks/useRouter'

const columns = [
  {
    key: 'id',
    label: 'ID',
    sorter: false,
  },
  {
    key: 'gameType',
    label: 'Loại trò chơi',
    sorter: false,
  },
  {
    key: 'name',
    label: 'Tên trò chơi',
    sorter: false,
  },
  {
    key: 'imageMobile',
    label: 'Ảnh mobile',
    sorter: false,
  },
  {
    key: 'imageDesktop',
    label: 'Ảnh desktop',
    sorter: false,
  },
  {
    key: 'minutes',
    label: 'Số phút',
    sorter: false,
  },
  {
    key: 'bi',
    label: 'Số kết quả',
    sorter: false,
  },
  {
    key: 'order',
    label: 'Thứ tự',
    sorter: false,
  },
  {
    key: 'status',
    label: 'Trạng thái',
    sorter: false,
  },
  {
    key: 'actions',
    label: 'Hành động',
    sorter: false,
    _props: { className: 'text-center' },
  },
]

interface IIndexGameName extends IGameName {
  index: number
}

const GameNameManagement = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { navigate, params } = useRouter()

  const { initialState } = useSelector(
    (state: RootState) => state.gameNameReducer,
  )

  const {
    filterState,
    totalItems,
    totalPages,
    loading,
    updateEntitySuccess,
    deleteEntitySuccess,
  } = initialState
  const [createUser, setCreateUser] = useState<boolean>(false)
  const [updateUser, setUpdateUser] = useState<IGameName | null>(null)
  const [currentDraw, setCurrentDraw] = useState<IGameName | null>(null)
  const [loadParent, setLoadParent] = useState<Boolean>(false)

  const handleCreateUser = () => {
    setCreateUser(true)
  }

  const handleUpdateUser = (gameName: IGameName) => () => {
    setUpdateUser(gameName)
  }

  const handleCancelUpdateUser = (update?: boolean) => {
    setUpdateUser(null)
  }

  const handlePaginationChange = (page: number) => {
    if (page !== 0) {
      window.scrollTo(0, 0)
      dispatch(setFilterState({ ...filterState, page: page }))
    }
  }

  const gameNames = useSelector(gameNameSelectors.selectAll)
  const indexedUser = createIndexes(gameNames, filterState)

  useEffect(() => {
    if (!deleteEntitySuccess) {
      dispatch(fetching())
      dispatch(
        getEntities({ ...filterState, gameNameId: Number(params?.id) ?? 1 }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), loadParent, params?.id])

  const [chosenDelete, setChosenDelete] = useState<IUser | null>(null)

  const handleDelete = () => {
    if (chosenDelete) {
      dispatch(removeEntity(chosenDelete.id))
      setChosenDelete(null)
    }
  }

  const handleCancelDelete = () => {
    setChosenDelete(null)
  }

  useEffect(() => {
    if (updateEntitySuccess && !createUser && !updateUser) {
      ToastSuccess('Cập nhật thành công')
      dispatch(resetEntity())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess])

  const handleUpdateStatus = (user: IGameName) => () => {
    const status =
      user.status === ResponseStatus.ACTIVE
        ? ResponseStatus.INACTIVE
        : ResponseStatus.ACTIVE
    dispatch(fetching())
    dispatch(updateEntity({ ...user, status }))
  }

  const gameTypes = useSelector(gameTypeSelectors.selectAll)

  useEffect(() => {
    dispatch(fetching())
    dispatch(
      getGameTypeEntities({ sortBy: 'order', sortOrder: 'ASC', limit: 100 }),
    )
  }, [])

  const handleChangeFilter = (e: any) => {
    dispatch(
      getEntities({
        ...filterState,
        gameTypeId: e.target.value,
        sortBy: 'order',
        sortOrder: 'ASC',
      }),
    )
  }

  return (
    <>
      <div className="tab-outlet-content">
        <div className="table-context">
          <div className="w-100">
            <CCol xs="6">
              <CFormLabel className="mb-6 label-gray-700">
                Tìm kiếm theo loại trò chơi
              </CFormLabel>
              <CFormSelect
                className="form-height-44"
                onChange={(e) => handleChangeFilter(e)}
              >
                <option value={0}>
                  Tất cả loại game
                </option>

                {gameTypes.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </CFormSelect>
            </CCol>
          </div>
        </div>
        <div className="table-content">
          <CSmartTable
            header
            columns={columns}
            items={indexedUser}
            clickableRows
            noItemsLabel="Không có bản ghi"
            itemsPerPage={filterState.limit}
            scopedColumns={{
              gameType: ({ index, ...value }: IIndexGameName) => (
                <td>{value?.gameType?.name}</td>
              ),
              imageDesktop: (item: IIndexGameName) => (
                <td>
                  <img height={60} src={item.imageDesktop} />
                </td>
              ),
              imageMobile: (item: IIndexGameName) => (
                <td>
                  <img height={60} src={item.imageMobile} />
                </td>
              ),
              status: ({ index, ...value }: IIndexGameName) => (
                <td>
                  <div className="d-flex align-items-center flex-nowrap">
                    <CFormSwitch
                      checked={value.status === ResponseStatus.ACTIVE}
                      className="me-2"
                      onChange={handleUpdateStatus(value)}
                      disabled={loading}
                    />
                  </div>
                </td>
              ),
              total: ({ index, ...value }: IIndexGameName) => <td></td>,
              startTime: ({ index, ...value }: IIndexGameName) => (
                <td>
                  <div
                    className={'d-flex align-items-center flex-nowrap'}
                  ></div>
                </td>
              ),
              endTime: ({ index, ...value }: IIndexGameName) => (
                <td>
                  <div
                    className={'d-flex align-items-center flex-nowrap'}
                  ></div>
                </td>
              ),
              // role: (value: IUser) => <td>{mapRoleToString[value.role]}</td>,
              actions: ({ index, ...value }: IIndexGameName) => {
                return (
                  <td>
                    <CInputGroup className="custom-input-group-action justify-content-center">
                      <CInputGroupText
                        onClick={() => navigate(`${value.id}/update`)}
                      >
                        <Edit02Icon />
                      </CInputGroupText>
                    </CInputGroup>
                  </td>
                )
              },
            }}
            tableProps={{
              hover: true,
              align: 'middle',
              responsive: 'lg',
              className: 'custom-table',
            }}
            tableHeadProps={{
              className: 'table-head',
            }}
            tableBodyProps={{
              className: 'table-body',
            }}
          />
        </div>
        <CRow>
          <CustomTableFooter
            totalItems={totalItems}
            hideSideChosen
            totalPages={totalPages}
            filterState={filterState}
            setFilterState={(filter) => dispatch(setFilterState(filter))}
            handlePaginationChange={handlePaginationChange}
          />
        </CRow>
      </div>
    </>
  )
}

export default GameNameManagement
