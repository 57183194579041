import { SVGProps } from "react"
const MessageAlertSquareSidebar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width||16}
    height={props?.height||16}
    fill="none"
    viewBox="0 0 16 16"
    stroke="currentColor"
    {...props}
  >
    <path
      fill={props?.fill}
      d="M2 5.2c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874C3.52 2 4.08 2 5.2 2h5.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874C14 3.52 14 4.08 14 5.2v3.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C12.48 12 11.92 12 10.8 12H9.122c-.416 0-.624 0-.823.04a2.002 2.002 0 0 0-.507.179c-.181.092-.344.222-.669.482l-1.59 1.272c-.277.222-.416.333-.533.333a.333.333 0 0 1-.26-.125c-.073-.091-.073-.269-.073-.624V12c-.62 0-.93 0-1.185-.068a2 2 0 0 1-1.414-1.414C2 10.263 2 9.953 2 9.333V5.2Z"
      opacity={0.12}
    />
    <path
      stroke={props?.stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
      d="M8 7V4.667m0 4.666h.007M4.667 12v1.557c0 .355 0 .533.072.624.064.08.16.126.261.126.117 0 .256-.112.533-.334l1.59-1.272c.325-.26.488-.39.669-.482.16-.082.33-.142.507-.178.2-.041.407-.041.824-.041H10.8c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C14 10.48 14 9.92 14 8.8V5.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C12.48 2 11.92 2 10.8 2H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 3.52 2 4.08 2 5.2v4.133c0 .62 0 .93.068 1.185a2 2 0 0 0 1.414 1.414c.255.068.565.068 1.185.068Z"
    />
  </svg>
)
export default MessageAlertSquareSidebar
