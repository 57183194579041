import { SVGProps } from "react"
const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={15}
    fill="none"
    viewBox="0 0 20 15"
    {...props}
  >
    <g fill="#FF5C00" clipPath="url(#a)">
      <path d="M1.17 0h4.033c.745 0 1.614.635 1.944 1.419l2.866 6.817-2.316 5.51c-.703 1.672-1.842 1.672-2.545 0L.17 1.891C-.271.847.178 0 1.17 0ZM18.83 0h-3.687c-.744 0-1.615.635-1.944 1.419l-2.562 6.025h5.483c.744 0 1.615-.635 1.944-1.418l1.767-4.134c.44-1.045-.009-1.892-1-1.892Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v15H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default Logo
