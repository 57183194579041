import { RootState } from '@/reducers'
import { IGame } from '@/shared/model/game.model'
import { AppDispatch } from '@/store'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../../../shared/config/axios-interceptor'
import { getProfile } from '../auth/auth.api'
import { formatVND } from '@/shared/utils/ultils'
import SModal from '@/components/shared/Modal/SModal'
import { CModalBody, CModalFooter } from '@coreui/react-pro'
import { IBiDatCuoc } from '@/shared/model/biDatCuoc.model'
import {
  mapBiDatCuocGroup,
  ResponseStatusUser,
} from '@/shared/enumeration/ResponseStatus'
import { ToastError, ToastSuccess } from '@/components/shared/toast/Toast'
import KyQuay from './KyQuay'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { getEntities } from '../HistoryManagement/historyManagement.api'
import { IGameName } from '@/shared/model/gameName.model'
import { IGameSidebar } from '@/shared/model/gameSidebar.model'
import BoxTienCuoc from './components/BoxTienCuoc'
import Header from './components/Header'
import BoxKetQua from './BoxKetQua'
import { setBoxChonCuoc } from '../auth/auth.reducer'

interface IProp {
  game: IGameName
}

const Pk10 = (props: IProp) => {
  const dispatch = useDispatch<AppDispatch>()
  const { params } = useRouter()
  const { game } = props
  const { user, boxChonCuoc } = useSelector(
    (state: RootState) => state.authentication,
  )
  const [chonCuoc, setChonCuoc] = useState<IBiDatCuoc[]>([])
  const [listKetQua, setListKetQua] = useState<IGame[]>([])

  const getListKetQua = async () => {
    try {
      const response = await axios.get(
        '/draws?gameId=' +
        params?.id +
        '&status=closed&sortBy=id&sortOrder=DESC',
      )
      setListKetQua(response.data.data)
    } catch (error) {
      // console.error('Error:', error)
    }
  }

  const [soTien, setSoTien] = useState<any>('')
  const [boxCuoc, setBoxCuoc] = useState<IGameSidebar[]>([])
  const [ketQuaKyTruoc, setKetQuaKyTruoc] = useState<IGame | null>(null)
  const [ky, setKy] = useState<IGame | null>(null)
  const [load, setLoad] = useState(false)
  const [loading, setLoading] = useState(false)

  const [showModalCuoc, setShowModalCuoc] = useState(false)

  const [tabLeft, setTabLeft] = useState(0)

  const { initialState } = useSelector(
    (state: RootState) => state.historyReducer,
  )
  const { filterState } = initialState

  useEffect(() => {
    setChonCuoc(boxChonCuoc)
  }, [ky])

  const datCuocHandle = (object: any) => {
    if (chonCuoc.some(it => it.biDatCuocId === object.biDatCuocId)) {
      let a = chonCuoc.filter(
        (item: any) => item.biDatCuocId !== object.biDatCuocId,
      )
      setChonCuoc(a)
    } else {
      setChonCuoc([...chonCuoc, object])
    }
  }

  const getBoxCuoc = async () => {
    try {
      const response = await axios.get('/bidatcuoc/listcuoc/' + params?.id)
      setBoxCuoc(response.data)
    } catch (error) {
      // console.error('Error:', error)
    }
  }

  const getKy = async () => {
    try {
      const response = await axios.get('/draws/get-current/' + params?.id)
      setKy(response.data)
    } catch (error) {
      // console.error('Error:', error)
    }
  }

  const getKetQuaKyTruoc = async () => {
    try {
      const response = await axios.get(
        '/draws/get-result-ket-qua-ky-truoc/' + params?.id,
      )
      setKetQuaKyTruoc(response.data)
    } catch (error) {
      // console.error('Error:', error)
    }
  }

  useEffect(() => {
    getListKetQua()
    getBoxCuoc()
    getKy()
    getKetQuaKyTruoc()
    dispatch(getProfile())
    dispatch(
      getEntities({
        ...filterState,
        userId: Number(user?.id) ?? 1,
        sortBy: 'historyId',
        sortOrder: 'DESC',
        status: null,
      }),
    )
  }, [load, game])

  const handleOnShow = () => {
    if (chonCuoc.length === 0) {
      ToastError('Vui lòng chọn ít nhất 1 mục cược')
      return
    }
    setShowModalCuoc(true)
  }


  const submitHanler = async () => {
    if (user?.status === ResponseStatusUser.CAMGIAODICH) {
      ToastError('Bạn bị cấm đạt cược. Vui lòng liên hệ CSKH để đặt cược')
      setShowModalCuoc(false)
      setChonCuoc([])
      return
    }
    try {
      if ((user?.money ?? 0) < soTien * chonCuoc.length) {
        ToastError('Số dư không đủ')
        return
      }

      if (soTien < 10) {
        ToastError('Số tiền cược phải lớn hơn 10')
        return
      }

      const data = {
        money: soTien,
        kyId: ky?.id,
        listDatCuoc: chonCuoc,
      }
      
      setLoading(true)
      const response = await axios.post('/history/create-all', data)
      if (response.data) {
        dispatch(getProfile())
        dispatch(
          getEntities({
            ...filterState,
            userId: Number(user?.id) ?? 1,
            sortBy: 'historyId',
            sortOrder: 'DESC',
            status: null,
          }),
        )
        setShowModalCuoc(false)
        setSoTien(0)
        ToastSuccess('Mua vé thành công')
        setLoading(false)
        dispatch(setBoxChonCuoc(chonCuoc))
      }
    } catch (error) {
      setShowModalCuoc(false)
      setLoading(false)
    }
  }


  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (Number.isNaN(Number(value))) return
    setSoTien(Number(value));
  }

  return (
    <div className="page-wap-game d-flex flex-column">
      <Header game={game} />
      <KyQuay
        load={load}
        ky={ky}
        setLoad={setLoad}
      />

      <BoxKetQua ketQuaKyTruoc={ketQuaKyTruoc} ky={ky} game={game} />

      <div className="main-content-game">
        <div
          className="tab tab1 d-flex container-box-game-cuoc"
          // style={{ height: window.innerHeight - 178 }}
        >
          <div className="sidebar-tab1">
            <div
              className={tabLeft === 0 ? 'active px-2' : 'px-2'}
              onClick={() => setTabLeft(0)}
            >
              Hai mặt
            </div>
            {game?.gameSidebar.map((item: IGameSidebar, index: number) => {
              return (
                <div
                  key={index}
                  className={tabLeft === item?.id ? 'active px-2' : 'px-2'}
                  onClick={() => setTabLeft(item?.id)}
                >
                  {item.name}
                </div>
              )
            })}
          </div>
          <div className="content-tab1">
            {boxCuoc.map((item: IGameSidebar, index: number) => {
              return (
                (tabLeft === item.id || tabLeft === 0) && (
                  <div className="box-item box-top" key={index}>
                    <h6 className="box-heading text-center">{item.name}</h6>
                    <div className="row g-2">
                      {item.biDatCuoc.map(
                        (item2: IBiDatCuoc, index2: number) => {
                          return (
                            <div className="col-6" key={index2}>
                              <div
                                className={
                                  chonCuoc.some(it => it.biDatCuocId === item2.biDatCuocId)
                                    ? 'item active d-flex align-items-center'
                                    : 'item d-flex align-items-center'
                                }
                                onClick={() => datCuocHandle(item2)}
                              >
                                <div
                                  style={{ fontSize: 15 }}
                                  className="name w-100"
                                >
                                  {item2.name}
                                </div>
                                <div className="rate w-100">
                                  {item2.rate}
                                </div>
                              </div>
                            </div>
                          )
                        },
                      )}
                    </div>
                  </div>
                )
              )
            })}
          </div>
        </div>

        <div className="footer-custom-game d-flex flex-row">
          <div className="footer-top">
            <div className="footer-top-right">
              <input
                type="text"
                value={soTien}
                onChange={onChange}
              />
            </div>
          </div>

          <div className="footer-bottom d-flex">
            <div className="button-submit">
              <div
                className={chonCuoc.length ? 'submit active' : 'submit'}
                onClick={() => handleOnShow()}
              >
                Xác nhận
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <SModal
          alignment="center"
          visible={showModalCuoc}
          backdrop="static"
          className="custom-modal-game custom-modal-datcuoc"
        >
          <CModalBody>
            <div>
              <div className="d-flex justify-content-between mb-3">
                <div className="fw-bold">Chi tiết đặt cược</div>
                <div>Thời hạn: 5 phút</div>
              </div>

              <div className="px-3 mt-4">
                <div className="mb-1"> Kỳ: {ky?.id}</div>
                <div className="mb-1"> Chi tiết:</div>
                <div className="border p-2" style={{ fontSize: 12 }}>
                  {chonCuoc.map((item: IBiDatCuoc, index: number) => {
                    return (
                      <div key={index}>
                        {mapBiDatCuocGroup[item?.group ?? 1]} - {item.name} - Số
                        tiền:{' '}
                        <strong style={{ color: '#ec0022' }}>
                          {formatVND(Number(soTien))}
                        </strong>{' '}
                      </div>
                    )
                  })}
                </div>

                <div className="mt-3">
                  Tổng tiền:{' '}
                  <strong style={{ color: '#ec0022' }}>
                    {formatVND(Number(soTien) * chonCuoc.length)}
                  </strong>
                </div>
              </div>
            </div>
          </CModalBody>
          <CModalFooter className="d-flex justify-content-around">
            <div
              className="cancel fw-bold"
              onClick={() => setShowModalCuoc(false)}
            >
              Đóng
            </div>

            <button
              className="submit fw-bold"
              onClick={submitHanler}
              disabled={loading}
              style={{ outline: 'none', border: 'none' }}
            >
              Xác nhận
            </button>
          </CModalFooter>
        </SModal>
      </div>
    </div>
  )
}

export default Pk10
