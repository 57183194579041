import { IHistory } from '@/shared/model/history.model'
import { formatVND } from '@/shared/utils/ultils'
import { useRef, useEffect, useState } from 'react'

function Card(props: any) {
  const { item, refCard } = props
  return (
    <div ref={refCard}
      className="d-flex justify-content-between pe-2 align-items-center"
      style={{ height: 60 }}
    >
      <div style={{ fontSize: 13 }}>
        <div>{item.biDatCuoc.game?.name}</div>
        <div>
          Mở thưởng kì trước{' '}
          <span style={{ color: '#ee3047' }}>{item.KyId}</span>
        </div>
      </div>
      <div style={{ fontSize: 13 }}>
        Số tiền trúng thưởng:{' '}
        <span style={{ color: '#ee3047' }}>
          {formatVND(item?.addMoney ?? 0)}
        </span>
      </div>
    </div>
  )
}

interface IProp {
  historys: IHistory[] | []
}

const AutoScrollLoop = (props: IProp) => {
  const ref: any = useRef(null)
  const [containerWidth, setWidth] = useState(100 + '%')
  const [animationState, setPlay] = useState('paused')
  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.scrollWidth + 'px')
      setPlay('running')
    }
  }, [])
  console.log(containerWidth)
  const renderCards = props.historys.map((el, index) => {
    return <Card key={index} item={el} />
  })

  return (
    <div className="App tet-app">
      <div
        className="d-flex list-item-bxh"
        ref={ref}
        style={{
          width: `${containerWidth}`,
          animationPlayState: animationState,
        }}
      >
        {renderCards}
        {renderCards}
      </div>
    </div>
  )
}

export default AutoScrollLoop
