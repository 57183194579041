import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

const GameTypeManagement = React.lazy(() => import('./GameTypeManagement'));

const GameTypeManagementLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      // <ProtectedComponent requiredPerms={[]}>
        <GameTypeManagement />
      // </ProtectedComponent>
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const GameTypesManagementRoutes = () => useRoutes(GameTypeManagementLayout);
export default GameTypesManagementRoutes;
