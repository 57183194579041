import Layout from './Layout'
import { NavLink } from 'react-router-dom'
import ArrowRight from '@/components/shared/icons/web/ArrowRight'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { AppDispatch } from '@/store'
import { logout, resetAll } from '../auth/auth.reducer'
import { CButton } from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import avatar1 from '../../../assets/img/avatar1.jpg'
import { formatVND } from '@/shared/utils/ultils'
import axios from '../../../shared/config/axios-interceptor'
import {
  AiFillAccountBook,
  AiFillCreditCard,
  AiOutlineCreditCard,
  AiOutlineFileSearch,
  AiOutlineHistory,
  AiOutlineIdcard,
  AiOutlineLogout,
  AiOutlineUser,
} from 'react-icons/ai'

const Account = () => {
  const { user } = useSelector((state: RootState) => state.authentication)
  const dispatch = useDispatch<AppDispatch>()

  const [tab, setTab] = useState(1)

  const onLogout = () => {
    dispatch(logout())
    dispatch(resetAll())
  }

  const [data, setData] = useState<any>({})

  const getKetQua = async () => {
    try {
      const response = await axios.get('/history/lai-lo')
      setData(response.data)
    } catch (error) {
      setData([])
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getKetQua()
  }, [])

  console.log(data)

  return (
    <Layout>
      <div className="box-account-wap">
        <div className="header-top">
          <div className="top text-center">
            <img src={avatar1} className="avatar  mt-4 mb-2" />
            <div style={{ color: '#fff', fontSize: 14 }}>{user?.username}</div>
            <div style={{ color: '#fff', fontSize: 14 }}>
              Số dư:{' '}
              <strong style={{ color: 'rgb(251, 229, 58)' }}>
                {formatVND(user?.money ?? 0)}
              </strong>
            </div>
          </div>

          {/* <div className="bottom d-flex justify-content-between align-items-center">
            <div
              style={{ color: '#fff', fontSize: 14 }}
              className="left text-center"
            >
              <span>Giao dịch hôm nay</span>
              <div className="mt-1">
                <span style={{ color: '#fbe53a' }}>
                  {formatVND(data?.moneyTotal ?? 0)}
                </span>
              </div>
            </div>
            <div
              className="text-center right"
              style={{ color: '#fff', fontSize: 14 }}
            >
              <span>Lãi lỗ hôm nay</span>
              <div className="mt-1" style={{ color: '#fbe53a' }}>
                {formatVND(data?.total ?? 0)}
              </div>
            </div>
          </div> */}
        </div>

        <div className="mt-1 box-nap-rut d-flex gap-2 mt-3">
          <NavLink
            className="text-decoration-none w-100 text-center mx-3 py-2 text-white"
            to={'/message'}
            style={{ background: '#ec0022' }}
          >
            Nạp tiền
          </NavLink>
          <NavLink
            className="text-decoration-none w-100 text-center mx-3 py-2 text-white"
            to={'/withdraw'}
            style={{ background: '#F60' }}
          >
            Rút tiền
          </NavLink>
        </div>

        <div className="main-content-account">
          {/* <div className="tab-header">
            <div
              className={
                tab == 1 ? 'tab-header-item active' : 'tab-header-item'
              }
              onClick={() => setTab(1)}
            >
              <img src={account1} />
              <div>Tài khoản</div>
            </div>

            <div
              className={
                tab == 2 ? 'tab-header-item active' : 'tab-header-item'
              }
              onClick={() => setTab(2)}
            >
              <img src={account2} />
              <div>Báo cáo</div>
            </div>

            <div
              className={
                tab == 3 ? 'tab-header-item active' : 'tab-header-item'
              }
              onClick={() => setTab(3)}
            >
              <img src={account3} />
              <div>Đội ngũ</div>
            </div>

            <div
              className={
                tab == 4 ? 'tab-header-item active' : 'tab-header-item'
              }
              onClick={() => setTab(4)}
            >
              <img src={account4} />
              <div>Hệ thống</div>
            </div>
          </div> */}

          <div className="tab-content">
            {tab == 1 && (
              <div className="tab1">
                <NavLink to="/account-info" className="">
                  <div className="d-flex align-items-center gap-2">
                    <AiOutlineUser />
                    <span>Thông tin cơ bản</span>
                  </div>
                  <ArrowRight className="ms-auito" color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to="/history-withdraw">
                  <div className="d-flex align-items-center gap-2">
                    <AiOutlineFileSearch />

                    <span>Lịch sử rút tiền</span>
                  </div>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to={'/message'}>
                  <div className="d-flex align-items-center gap-2">
                    <AiFillCreditCard />
                    <span>Nạp tiền</span>
                  </div>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to={'/withdraw'}>
                  <div className="d-flex align-items-center gap-2">
                    <AiOutlineCreditCard />
                    <span>Rút tiền</span>
                  </div>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to="/add-bank">
                  <div className="d-flex align-items-center gap-2">
                    <AiOutlineIdcard />
                    <span>Thẻ ngân hàng</span>
                  </div>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to="/history-order">
                  <div className="d-flex align-items-center gap-2">
                    <AiOutlineHistory />
                    <span>Lịch sử đặt cược</span>
                  </div>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>
              </div>
            )}

            {tab == 2 && (
              <div className="tab1">
                <NavLink to="/history-order">
                  <span>Lịch sử cược</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to="/history-order">
                  <span>Lịch sử nạp</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to={'/history-order'}>
                  <span>Lịch sử rút</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>
              </div>
            )}

            {tab == 3 && (
              <div className="tab1">
                <NavLink to="/magioithieu">
                  <span>Mã giới thiệu</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>
              </div>
            )}

            {tab == 4 && (
              <div className="tab1">
                <NavLink to="/uudai">
                  <span>Ưu đãi</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to="/uudai">
                  <span>Tin tức</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to={'/message'}>
                  <span>CSKH</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>
              </div>
            )}
          </div>

          <div className="logout">
            <CButton onClick={onLogout}>Đăng xuất</CButton>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Account
