import axios from '@/shared/config/axios-interceptor';
import { IMessage } from '@/shared/model/message.model';
import { IParams } from '@/shared/shared-interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';

const prefix = 'message';

export interface IMessageParams extends IParams {
  roomId: number
}

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IMessageParams, thunkAPI) => {
  try {
    const { roomId } = fields
    const params = pickBy(fields);
    return await axios.get<IMessage[]>(`${prefix}/room/${roomId}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});