import { NavLink } from "react-router-dom"

const Info = () => {
  return (
    <div>
      <div className="d-flex justify-content-between p-3 border-bottom">
        <div>Quy định</div>
        <NavLink to={'/'} style={{fontSize: 30, lineHeight: "20px", color: "#000"}} className="text-decoration-none">×</NavLink>
        
      </div>

      <div className="p-3">
        <p>
          Mở thưởng theo thứ tự tự từ trái qua phải bắt đầu từ
          <strong style={{color: "orange"}}> Chục Ngàn, Ngàn, Trăm, Chục, Đơn vị</strong>
        </p>
        <p>
          Để đảm bảo web được hoạt động lâu dài cũng như bắt buộc duy trì các
          hoạt động đóng thuế cho doanh nghiệp, đối với các quý khách nhận được
          phần quà ngẫu nhiên may mắn từ Web, khi rút điểm cần thực Hiện đóng
          phí duy trì theo hạn mức rút điểm như sau:
        </p>
        <ul className="ps-3">
          <li>Hạn mức rút tài khoản dưới <span style={{color: "orange"}}>200 triệu</span> tương ứng  <span style={{color: "orange"}}>15% phí</span>.</li>
          <li>Hạn mức rút tài khoản dưới <span style={{color: "orange"}}>500 triệu</span> tương ứng với <span style={{color: "orange"}}>20% phí</span>.</li>
          <li>Hạn mức rút tài khoản trên <span style={{color: "orange"}}>500 triệu</span> tương ứng với <span style={{color: "orange"}}>30% phí</span>.</li>
        </ul>
      </div>
    </div>
  )
}

export default Info
