import { RootState } from '@/reducers'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { socket } from '@/shared/config/socket'
import { AppDispatch } from '@/store'
import { getProfile } from '../auth/auth.api'
import { ITransaction } from '@/shared/model/transaction.model'

import logo from '../../../assets/img/mobile_logo.png'

import { NavLink } from 'react-router-dom'
import { formatVND } from '@/shared/utils/ultils'
import { logout, setPopup } from '../auth/auth.reducer'
import { resetAll } from './webManagement.reducer'
import axios from '../../../shared/config/axios-interceptor'

import showWhite from '../../../assets/img/show-white.png'
import logoAvatar from '../../../assets/img/logo3.png'
import CSKH from '../../../assets/img/CSKH.jpg'

const Header = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.authentication)
  const [visible, setVisible] = useState(false)
  const [deposit, setDeposit] = useState<ITransaction>()
  const { popup } = useSelector((state: RootState) => state.authentication)
  const handleOnCloseNoti = () => {
    dispatch(setPopup())
  }

  const [notifiFirstLogin, setNotifiFirstLogin] = useState<any>(null)

  const getNofitiFirstLogin = async () => {
    try {
      const response = await axios.get('/config/1')
      setNotifiFirstLogin(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getNofitiFirstLogin()

    socket.on('connect', () => {
      console.log('Connected to server')
    })

    socket.on(`user_${user?.id}_new_message`, (newMsg: any) => {
      console.log('Bạn có tin nhắn mới: ', newMsg)
    })
    console.log('data', user?.id)

    socket.on(`user_${user?.id}_new_deposit`, (data: any) => {
      console.log('data', data)

      if (data) {
        dispatch(getProfile())
      }

      if (data && data.isNotify) {
        setDeposit(data)
        setVisible(true)
      }
    })

    return () => {
      // console.log('disconnect');
      // socket.off('connect')
      socket.off(`user_${user?.id}_new_message`)
      socket.off(`user_${user?.id}_new_deposit`)
      // socket.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnClose = () => {
    setVisible(false)
  }

  const onLogout = () => {
    dispatch(logout())
    dispatch(resetAll())
  }

  const getLocal = localStorage.getItem('showTien');
  let a = false;
  if (getLocal) a = true
  console.log(getLocal);
  const [showTien, setShowTien] = useState(a)

  const onShowTien = () => {
    const data = !showTien;
    if (data === true) {
      localStorage.setItem('showTien', 'true')
    } else {
      localStorage.removeItem('showTien')
    }
    setShowTien(data)
  }

  return (
    <>
      <div className="header-web d-flex">
        <div className="logo">
          <NavLink to={'/'}>
            <img width={160} src={logo} />
          </NavLink>
        </div>

        <div className="d-flex justify-content-between w-100">
          <div className="menu-left d-flex align-items-center gap-4">
            <NavLink to={'/'}>Trang chủ</NavLink>
          </div>

          <div className="menu-left d-flex align-items-center gap-4">
            <NavLink className="d-flex align-items-center gap-2" style={{ fontSize: 13 }} to={'/message'}>
              <img
                width={32}
                height={32}
                style={{ borderRadius: '50%' }}
                src={CSKH}
              />
              CSKH
            </NavLink>
          </div>

          <div className="menu-right d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center align-items-center gap-2 me-4">
              <NavLink className="text-decoration-none text-white" style={{ fontSize: 13 }} to={'/tai-khoan'}>
                Trung tâm người dùng
              </NavLink>
            </div>

            <div className="d-flex align-items-center gap-2">
              <NavLink to={'/tai-khoan'}>
                <img
                  width={32}
                  height={32}
                  style={{ borderRadius: '50%' }}
                  src={logoAvatar}
                />
              </NavLink>
              <span className="me-4" style={{ fontSize: 14, color: '#fff' }}>
                {user?.username}
              </span>
            </div>

            <div className="d-flex align-items-center align-items-center gap-2 me-4">
              <span style={{ fontSize: 14, color: '#fff' }}>
                Số dư: {showTien ? formatVND(user?.money ?? 0) : '****'}
              </span>
              <img onClick={onShowTien} style={{ cursor: 'pointer' }} src={showWhite} width={20} />
            </div>

            <div className="d-flex align-items-center gap-2 me-4">
              <NavLink to={'/message'} className="nap-tien">
                Nạp Tiền
              </NavLink>
              <NavLink to={'/rut-tien'} className="rut-tien">
                Rút Tiền
              </NavLink>
            </div>

            <div className="d-flex align-items-center gap-2 me-4">
              <div onClick={onLogout} style={{ color: '#fff' }}>
                Đăng xuất
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
