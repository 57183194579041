import { SVGProps } from 'react';
const UserSquareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    stroke="currentColor"
    {...props}
  >
    <path
      stroke="#364152"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.667 14.545c.401.121.944.121 1.866.121h6.934c.922 0 1.464 0 1.866-.121m-10.666 0a1.469 1.469 0 0 1-.242-.097 2 2 0 0 1-.874-.874c-.218-.428-.218-.988-.218-2.108V4.533c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.428-.218.988-.218 2.108-.218h6.934c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v6.933c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874c-.076.039-.156.07-.242.097m-10.666 0c0-.54.003-.825.05-1.066a2.667 2.667 0 0 1 2.096-2.095c.258-.051.567-.051 1.187-.051h4c.62 0 .93 0 1.187.051a2.667 2.667 0 0 1 2.095 2.095c.048.24.051.526.051 1.066m-2.666-8.212a2.667 2.667 0 1 1-5.334 0 2.667 2.667 0 0 1 5.334 0Z"
    />
  </svg>
);
export default UserSquareIcon;
