import ArrowLeft from '@/components/shared/icons/web/ArrowLeft'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import bottomWhite from '../../../../assets/img/bottom-white.png'
import { CCol, CRow } from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import axios from '../../../../shared/config/axios-interceptor'
import { IGameType } from '@/shared/model/gameType.model'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { formatVND } from '@/shared/utils/ultils'

interface IProp {
  game: any
}
const Header = (props: IProp) => {
  const { game } = props
  const { navigate } = useRouter()
  const [gameType, setGameType] = useState<IGameType[]>([])
  const { user } = useSelector((state: RootState) => state.authentication)

  const [tab, setTab] = useState(false)

  const getGameType = async () => {
    try {
      const response = await axios.get(
        '/game-type?status=ACTIVE&sortBy=order&sortOrder=ASC&statusGame=ACTIVE',
      )
      setGameType(response.data.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    setTab(false)
  }, [game])

  useEffect(() => {
    getGameType()
  }, [])

  return (
    <div className="header-top d-flex justify-content-between align-item-center header-top-wap-game">
      <NavLink to={'/'} style={{ flexGrow: 1, flexBasis: 1 }}>
        <ArrowLeft />
      </NavLink>
      <div className="title" style={{ fontSize: 14 }}>
        {game?.name}
      </div>
      <div className='text-end' style={{ color: "#fff", fontSize: 12, flexGrow: 1, flexBasis: 1 }}>
        Số dư: {formatVND(user?.money || 0)}
      </div>

      {/* {tab && (
        <div className="menu-mobile">
          {gameType.map((item, index) => {
            return (
              <div className="box-list pb-3" key={index}>
                <div className="my-2" style={{ color: '#999', fontSize: 15 }}>
                  {item.name}
                </div>
                <CRow>
                  {item.game.map((game, index) => {
                    return (
                      <CCol xs={4} key={index}>
                        <NavLink
                          to={`/game/${game.id}`}
                          className="item text-decoration-none"
                        >
                          {game.name}
                        </NavLink>
                      </CCol>
                    )
                  })}
                </CRow>
              </div>
            )
          })}
        </div>
      )} */}
    </div>
  )
}

export default Header
