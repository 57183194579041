import { IGame } from '@/shared/model/game.model'
import { IGameName } from '@/shared/model/gameName.model'
import RenderImageF1 from '../../Wap/RenderImageF1'

interface IProp {
  ketQuaKyTruoc: IGame | null
  game: IGameName | null
}

const EsporttKetQua = (props: IProp) => {
  const { ketQuaKyTruoc, game } = props
  return (
    <div className="d-flex flex-column">
      <div className="fw-bold" style={{ color: '#000', fontSize: 12 }}>
        kỳ: {ketQuaKyTruoc?.id}
      </div>
      <div className="d-flex item-bi-quay">
        {Array.from({ length: game?.bi ?? 10 }, (_, i) => i + 1).map(
          (_, index) => {
            const propertyName: any = `ball${index + 1}`
            const ball = ketQuaKyTruoc?.[propertyName as keyof IGame] ?? 0
            // Kiểm tra và xử lý giá trị ball nếu cần
            let ballValue: React.ReactNode = null
            if (typeof ball === 'number' || typeof ball === 'string') {
              ballValue = ball
            } else {
              ballValue = '' // hoặc giá trị mặc định phù hợp
            }
            return (
              <div key={index} className={'item ' + ball}>
                <RenderImageF1 ball={Number(ball)} />
              </div>
            )
          },
        )}
      </div>
    </div>
  )
}

export default EsporttKetQua
