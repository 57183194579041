import { cisCameraPlus } from '@coreui/icons-pro'
import { cilChevronLeftAlt, cilMenu, cisPlus } from '@coreui/icons-pro'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CForm,
  CFormCheck,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CImage,
  CInputGroup,
  CLoadingButton,
  CRow,
  CSpinner,
} from '@coreui/react-pro'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { BiEdit, BiTrash } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'

import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd'
import { BsArrowDownUp } from 'react-icons/bs'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { RootState } from '@/reducers'

import { getEntities as geBiDatCuocEntities } from '../BiDatCuoc/biDatCuocManagement.api'

import { getEntities as getGameTypeEntities } from '../GameTypeManagement/gameTypeManagement.api'

import {
  getEntities as getGameSidebarEntities,
  updateGameSidebarEntities,
  updateDeleteEntity as deleteSidebar,
} from './GameSidebar/gameSidebarManagement.api'
import {
  createEntity,
  getEntities,
  getEntity,
  updateEntity,
} from './gameNameManagement.api'
import { IGameSidebar } from '@/shared/model/gameSidebar.model'
import { IBiDatCuoc } from '@/shared/model/biDatCuoc.model'

import {
  fetching as fetchingGameSidebar,
  gameSidebarSelectors,
  initialUsersFilter,
  resetAll as resettGameSiderAll,
  resetEntity as resetStepEntity,
} from './GameSidebar/gameSidebarManagement.reducer'
import {
  biDatCuocSelectors,
  fetching as fetchingBiDatCuoc,
  resetEntity as resetTaskEntity,
} from '../BiDatCuoc/biDatCuocManagement.reducer'

import {
  fetching,
  resetAll,
  selectEntityById,
} from './gameNameManagement.reducer'
import { IGameName, INewGameName } from '@/shared/model/gameName.model'
import {
  mapResponseStatus,
  ResponseStatus,
  responseStatusArray,
} from '@/shared/enumeration/ResponseStatus'
import { ToastError, ToastSuccess } from '@/components/shared/toast/Toast'
import {
  ErrorMessage,
  mapErrorMessage,
} from '@/shared/enumeration/errorMessage'
import GameSidebarModal from './GameSidebar/GameSidebarModal'
import BiDatCuocModal from '../BiDatCuoc/BiDatCuocModal'
import DeleteModal from '@/components/shared/ModalDelete/DeleteModal'
import { gameTypeSelectors } from '../GameTypeManagement/gameTypeManagement.reducer'
import { handleUploadImage } from '@/shared/utils/ultils'
import { checkErrorInputFile } from '@/components/shared/AvatarUpload/AvatarUploadContainer'

interface IGameSidebarBox {
  stageItem: IGameSidebar
  active: boolean
  visible: boolean
  drag: DraggableProvided
  index: number
}

interface IBiDatCuocBox {
  index: number
  taskItem: IBiDatCuoc
}

const GameNameUpdate = () => {
  const { navigate, params } = useRouter()
  const location = useLocation()
  const currentLocation = location.pathname
  const isDetail = currentLocation.includes('detail')
  const { id } = params
  const dispatch = useDispatch()
  const { initialState } = useSelector(
    (state: RootState) => state.gameNameReducer,
  )
  const { initialState: stepInitialState } = useSelector(
    (state: RootState) => state.gameSidebarReducer,
  )
  const { initialState: taskInitialState } = useSelector(
    (state: RootState) => state.biDatCuocReducer,
  )

  const gameTypes = useSelector(gameTypeSelectors.selectAll)

  const {
    updateEntitySuccess: updateStepSuccess,
    deleteEntitySuccess: deleteStepSuccess,
    updateStepIndexSuccess,
    loading: loadingStep,
  } = stepInitialState
  const {
    updateEntitySuccess: updateTaskSuccess,
    deleteEntitySuccess: deleteTaskSuccess,
  } = taskInitialState
  const { updateEntitySuccess, loading, errorMessage, newEntity } = initialState
  const [chosenStep, setChosenStep] = useState<IGameSidebar | null>(null)

  const [createStep, setCreateStep] = useState<boolean>(false)
  const [updateStep, setUpdateStep] = useState<boolean>(false)
  const [chosenUpdateStep, setChosenUpdateStep] = useState<IGameSidebar | null>(
    null,
  )

  const [createTask, setCreateTask] = useState<boolean>(false)
  const [updateTask, setUpdateTask] = useState<boolean>(false)
  const [chosenUpdateTask, setChosenUpdateTask] = useState<IBiDatCuoc | null>(
    null,
  )

  const procedure = useSelector(selectEntityById(id || ''))
  const procedureStep = useSelector(gameSidebarSelectors.selectAll)
  const procedureTask = useSelector(biDatCuocSelectors.selectAll)

  const [proceduceStepItems, setProceduceStepItems] = useState<IGameSidebar[]>(
    [],
  )
  const [visibleChangeItems, setVisibleChangeItems] = useState<boolean>(false)

  // const [businessAreasVisible, setBusinessAreasVisible] = useState<boolean>(
  //   false,
  // )
  // const businessAreas = useSelector(businessAreaSelectors.selectAll)

  const initialValues: IGameName = {
    id: 0,
    name: '',
    avatar: '',
    minutes: 0, /// Số phút
    bi: 0, /// Số bi
    group: 0,
    status: ResponseStatus.ACTIVE,
    gameSidebar: [],
    gameTypeId: 0,
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required('Vui lòng nhập tên trò chơi'),
    minutes: Yup.number().required('Vui lòng nhập số phút'),
    bi: Yup.number().required('Vui lòng nhập số bi'),
  })

  useEffect(() => {
    if (id) {
      dispatch(fetching())
      dispatch(getEntity(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (
      id &&
      !updateStepSuccess &&
      !deleteStepSuccess &&
      !updateStepIndexSuccess
    ) {
      dispatch(fetchingGameSidebar())
      dispatch(
        getGameSidebarEntities({
          page: 1,
          limit: 100,
          gameId: Number(id),
          sortBy: 'index',
          sortOrder: 'asc',
        }),
      )

      dispatch(getGameTypeEntities({ limit: 100 }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, updateStepSuccess, deleteStepSuccess, updateStepIndexSuccess])

  useEffect(() => {
    if (procedureStep.length > 0) {
      setProceduceStepItems(procedureStep)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procedureStep])

  useEffect(() => {
    if (updateStepIndexSuccess) {
      ToastSuccess('Chỉnh sửa danh sách danh sách thành công')
      dispatch(resettGameSiderAll())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStepIndexSuccess])

  useEffect(() => {
    if (chosenStep && !updateTaskSuccess && !deleteTaskSuccess) {
      dispatch(fetchingBiDatCuoc())
      dispatch(
        geBiDatCuocEntities({
          page: 1,
          limit: 100,
          sort: 'createdDate,desc',
          gameSidebarId: Number(chosenStep.id),
          sortBy: 'index',
          sortOrder: 'asc',
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenStep, updateTaskSuccess, deleteTaskSuccess])

  useEffect(() => {
    if (updateEntitySuccess && id) {
      ToastSuccess('Chỉnh sửa trò chơi thành công')
      // dispatch(resetEntity())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess])

  useEffect(() => {
    if (updateEntitySuccess && newEntity) {
      ToastSuccess('Tạo quy trình thành công')
      navigate(`/procedure/${newEntity.id}/update`)
      // dispatch(resetEntity())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess, newEntity])

  useEffect(() => {
    if (errorMessage) {
      ToastError(
        mapErrorMessage[errorMessage as ErrorMessage] ||
          'Có lỗi xảy ra, xin vui lòng thử lại!',
      )
      dispatch(resetAll())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage])

  useEffect(() => {
    if (deleteStepSuccess) {
      ToastSuccess('Xoá bản ghi giải thành công')
      dispatch(resetStepEntity())
      setChosenStep(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStepSuccess])

  useEffect(() => {
    if (deleteTaskSuccess) {
      ToastSuccess('Xoá bản ghi nhiệm vụ thành công')
      dispatch(resetTaskEntity())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTaskSuccess])

  const [isDeleteStep, setIsDeleteStep] = useState<boolean>(false)
  const [chosenDeleteStep, setChosenDeleteStep] = useState<IGameSidebar | null>(
    null,
  )
  const handleRemoveStep = () => {
    if (chosenDeleteStep) {
      const body = {
        id: [Number(chosenDeleteStep.id)],
        // status: ResponseStatus.DELETED,
      }
      dispatch(fetchingGameSidebar())
      dispatch(deleteSidebar(body))
      setChosenDeleteStep(null)
    }
  }

  const handleConfirmDeleteStep = (item: IGameSidebar) => {
    setChosenDeleteStep(item)
    setIsDeleteStep(true)
  }

  const [isDeleteTask, setIsDeleteTask] = useState<boolean>(false)
  const [chosenDeleteTask, setChosenDeleteTask] = useState<IBiDatCuoc | null>(
    null,
  )
  const handleRemoveTask = () => {
    if (chosenDeleteTask) {
      const body = {
        id: [Number(chosenDeleteTask.biDatCuocId)],
        // status: ResponseStatus.DELETED,
      }
      dispatch(fetchingBiDatCuoc())
      // dispatch(deleteTask(body))
      setChosenDeleteTask(null)
    }
  }

  const handleConfirmDeleteTask = (item: IBiDatCuoc) => {
    setChosenDeleteTask(item)
    setIsDeleteTask(true)
  }

  const handleCloseCreateStepModal = () => {
    setCreateStep(false)
  }

  const handleCloseUpdateStepModal = () => {
    setUpdateStep(false)
    setChosenUpdateStep(null)
  }

  const handleOpenUpdateStepModal = (step: IGameSidebar) => {
    setUpdateStep(true)
    setChosenUpdateStep(step)
  }

  const handleCloseCreateTaskModal = () => {
    setCreateTask(false)
  }

  const handleCloseUpdateTaskModal = () => {
    setUpdateTask(false)
    setChosenUpdateTask(null)
  }

  const handleOpenUpdateTaskModal = (task: IBiDatCuoc) => {
    setUpdateTask(true)
    setChosenUpdateTask(task)
  }

  const ProcedureStepBox = (props: IGameSidebarBox) => {
    const { stageItem, active, visible, drag } = props

    const handleChosenBox = () => {
      setChosenStep(chosenStep?.id === stageItem.id ? null : stageItem)
    }

    return (
      <div
        onClick={visible ? () => setChosenStep(null) : handleChosenBox}
        className={`${
          active ? 'green-fade-btn' : 'enterprise-table-box'
        }  d-flex justify-content-between mb-3 rounded cursor-pointer border-1 border`}
      >
        <div className="d-flex">
          <div
            className={`py-auto px-2 ${
              visible ? '' : 'd-none'
            } d-flex align-items-center border-right`}
            {...drag.dragHandleProps}
          >
            <CIcon icon={cilMenu} size="lg" />
          </div>
          <div
            className={`${
              visible ? 'py-3 pe-2 ps-1' : 'p-3'
            } d-flex flex-wrap justify-content-start`}
          >
            <div>
              <p className="box-title fw-bold m-0 text-md">{stageItem.name}</p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-start">
          <div
            className={` py-3 me-2 d-flex flex-column justify-content-center`}
          >
            <BiEdit
              color="#697586"
              size={18}
              className="cursor-pointer hover-info"
              title="Chỉnh sửa tiêu đề"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleOpenUpdateStepModal(stageItem)
              }}
            />
          </div>
          <div
            className={` py-3 me-2 d-flex flex-column justify-content-center  `}
          >
            <BiTrash
              color="#697586"
              size={18}
              className="cursor-pointer hover-danger"
              title="Xoá tiêu đề"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleConfirmDeleteStep(stageItem)
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  const ProcedureTaskBox = (props: IBiDatCuocBox) => {
    const { taskItem } = props
    return (
      <div
        className={`enterprise-table-box d-flex justify-content-between mb-3 rounded cursor-pointer border-1 border`}
        style={{ background: '#fcfcfd' }}
      >
        <div className="p-3 d-flex flex-wrap justify-content-start">
          <div>
            <p className="box-title m-0 fw-bold text-md">
              Tên: {taskItem.name}
            </p>
            <p className="box-title m-0 text-sm">Tỷ lệ: {taskItem.rate}</p>
          </div>
        </div>
        <div className="d-flex justify-content-start">
          <div
            className={` py-3 me-2 d-flex flex-column justify-content-center`}
          >
            <BiEdit
              color="#697586"
              size={18}
              className="cursor-pointer hover-info"
              title="Chỉnh sửa nhiệm vụ"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleOpenUpdateTaskModal(taskItem)
              }}
            />
          </div>
          <div
            className={` py-3 me-2 d-flex flex-column justify-content-center`}
          >
            <BiTrash
              color="#697586"
              size={18}
              className="cursor-pointer hover-danger"
              title="Xoá nhiệm vụ"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleConfirmDeleteTask(taskItem)
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  const reorder = (
    list: IGameSidebar[],
    startIndex: number,
    endIndex: number,
  ) => {
    const results = Array.from(list)
    const [removed] = results.splice(startIndex, 1)
    results.splice(endIndex, 0, { ...removed })
    const resultOrder = results.map((ele, index) => {
      return { ...ele, index }
    })
    return resultOrder
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return
    const newItems = reorder(
      proceduceStepItems,
      result.source.index,
      result.destination.index,
    )
    setProceduceStepItems(newItems)
  }

  const handleUpdateStep = () => {
    if (proceduceStepItems.length) {
      const body = proceduceStepItems.map((ele) => {
        return { id: ele.id, index: ele.index! + 1 }
      })
      dispatch(fetchingGameSidebar())
      dispatch(updateGameSidebarEntities({ data: body }))
      setVisibleChangeItems(false)
    }
  }

  const handleClickUpdateStep = () => {
    setVisibleChangeItems(!visibleChangeItems)
    setChosenStep(null)
    setProceduceStepItems(procedureStep)
  }

  const handleConfirmManufacture = (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => (chosenItem: number[]) => {
    setFieldValue('productTypeId', chosenItem[0])
  }

  const handleFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    const inputFiles = e.target.files
    if (inputFiles && inputFiles[0]) {
      if (!checkErrorInputFile([inputFiles[0]])) return
      const reader = new FileReader()
      reader.onload = (e) => {
        setFieldValue('fileMobile', inputFiles[0])
        setFieldValue('imageMobile', e.target?.result)
      }
      reader.readAsDataURL(inputFiles[0])
    }
  }

  const handleFileUploadDesktop = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    const inputFiles = e.target.files
    if (inputFiles && inputFiles[0]) {
      if (!checkErrorInputFile([inputFiles[0]])) return
      // setProfilePicFile(inputFiles[0]);
      const reader = new FileReader()
      reader.onload = (e) => {
        setFieldValue('fileDesktop', inputFiles[0])
        setFieldValue('imageDesktop', e.target?.result)
      }
      reader.readAsDataURL(inputFiles[0])
    }
  }

  const handleRemappingImageValue = async (value: INewGameName) => {
    if (value.fileDesktop) {
      const avatarUrlPromises = await handleUploadImage(value.fileDesktop)
      value.imageDesktop = avatarUrlPromises
    }
    if (value.fileMobile) {
      const avatarUrlPromises = await handleUploadImage(value.fileMobile)
      value.imageMobile = avatarUrlPromises
    }
    return value
  }

  return (
    <>
      <div className="tab-outlet-content" style={{ background: '#f6f6f6' }}>
        <CCard
          className="bg-cms border-0 shadow-none"
          style={{ background: '#fff' }}
        >
          <CCardHeader
            className=" bg-cms border-0 px-0 d-flex align-items-center gap-4"
            style={{ background: '#fff' }}
          >
            <CButton
              className={`btn-custom primary-500 btn-xs`}
              onClick={() => navigate('/admin/game')}
            >
              <CIcon icon={cilChevronLeftAlt} size="sm" className="mx-1" /> Quay
              lại
            </CButton>
            <CCardTitle className="">
              {id ? 'Chỉnh sửa trò chơi' : 'Tạo mới trò chơi'}
            </CCardTitle>
          </CCardHeader>

          <Formik
            enableReinitialize
            initialValues={procedure || initialValues}
            validationSchema={validationSchema}
            onSubmit={async (value) => {
              const values = await handleRemappingImageValue(value)
              dispatch(fetching())
              if (id) {
                dispatch(updateEntity(values as IGameName))
              } else {
                dispatch(createEntity(values))
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleSubmit,
              resetForm,
              handleBlur,
            }) => (
              <CForm onSubmit={handleSubmit} className="bg-white">
                <CCardHeader className="border border-bottom-0 d-flex flex-wrap justify-content-between bg-transparent">
                  <CCardTitle className="my-1">Thông tin trò chơi</CCardTitle>
                </CCardHeader>
                <CCardBody className="border rounded-bottom">
                  <CRow>
                    <CCol xs={6}>
                      <CRow>
                        <CCol xs={12}>
                          <CFormLabel className="fw-bold text-sm mb-0 required-field">
                            Chọn ảnh đại diện cho mobile
                          </CFormLabel>
                          <div className="d-flex justify-content-center">
                            <div className="profileImageContainer">
                              <div
                                className="position-relative"
                                style={{ height: 100 }}
                              >
                                {values.imageMobile ? (
                                  <CImage
                                    color="secondary"
                                    rounded
                                    className="avatar-xxl object-fit-cover"
                                    src={values.imageMobile}
                                    style={{ width: '100%', height: '100%' }}
                                  />
                                ) : (
                                  <div
                                    className="d-flex flex-column justify-content-center align-items-center"
                                    style={{ width: '100%', height: '100%' }}
                                  >
                                    <CIcon
                                      icon={cisCameraPlus}
                                      className="mb-3"
                                      style={{ width: '33px', height: '33px' }}
                                    />
                                    <p>Chọn ảnh</p>
                                  </div>
                                )}
                              </div>
                              <CFormLabel
                                htmlFor="custom-task-file-input"
                                className={`p-image`}
                              >
                                {values.imageMobile ? (
                                  <CIcon
                                    icon={cisCameraPlus}
                                    color="secondary"
                                    className="upload-button"
                                  />
                                ) : (
                                  <div className="add-image"></div>
                                )}
                                <input
                                  id="custom-task-file-input"
                                  className="file-upload"
                                  type="file"
                                  onClick={(e) => (e.currentTarget.value = '')}
                                  // name="imageUrl"
                                  onChange={(e) =>
                                    handleFileUpload(e, setFieldValue)
                                  }
                                  // accept={inputFileTypesAccept}
                                  accept="image/*"
                                />
                              </CFormLabel>
                            </div>
                          </div>
                          <CFormFeedback
                            invalid
                            className={
                              !!errors.imageMobile && touched.imageMobile
                                ? 'd-block'
                                : 'd-none'
                            }
                          >
                            {errors.imageMobile}
                          </CFormFeedback>
                        </CCol>
                      </CRow>
                    </CCol>

                    <CCol xs={6}>
                      <CRow>
                        <CCol xs={12}>
                          <CFormLabel className="fw-bold text-sm mb-0 required-field">
                            Chọn ảnh đại diện cho web
                          </CFormLabel>
                          <div className="d-flex justify-content-center">
                            <div className="profileImageContainer">
                              <div
                                className="position-relative"
                                style={{ height: 100 }}
                              >
                                {values.imageDesktop ? (
                                  <CImage
                                    color="secondary"
                                    rounded
                                    className="avatar-xxl object-fit-cover"
                                    src={values.imageDesktop}
                                    style={{ width: '100%', height: '100%' }}
                                  />
                                ) : (
                                  <div
                                    className="d-flex flex-column justify-content-center align-items-center"
                                    style={{ width: '100%', height: '100%' }}
                                  >
                                    <CIcon
                                      icon={cisCameraPlus}
                                      className="mb-3"
                                      style={{ width: '33px', height: '33px' }}
                                    />
                                    <p>Chọn ảnh</p>
                                  </div>
                                )}
                              </div>
                              <CFormLabel
                                htmlFor="custom-task-file-input-1"
                                className={`p-image`}
                              >
                                {values.imageDesktop ? (
                                  <CIcon
                                    icon={cisCameraPlus}
                                    color="secondary"
                                    className="upload-button"
                                  />
                                ) : (
                                  <div className="add-image"></div>
                                )}
                                <input
                                  id="custom-task-file-input-1"
                                  className="file-upload"
                                  type="file"
                                  onClick={(e) => (e.currentTarget.value = '')}
                                  onChange={(e) =>
                                    handleFileUploadDesktop(e, setFieldValue)
                                  }
                                  accept="image/*"
                                />
                              </CFormLabel>
                            </div>
                          </div>
                          <CFormFeedback
                            invalid
                            className={
                              !!errors.imageMobile && touched.imageMobile
                                ? 'd-block'
                                : 'd-none'
                            }
                          >
                            {errors.imageMobile}
                          </CFormFeedback>
                        </CCol>
                      </CRow>
                    </CCol>

                    <CCol xs={12} md={6} className="mb-3">
                      <CFormLabel className="m-0 fw-bold" htmlFor="name">
                        Tên trò chơi <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        invalid={!!errors.name && touched.name}
                        id="name"
                        name="name"
                        value={values.name}
                        placeholder="Nhập trò chơi"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isDetail}
                      />
                      <CFormFeedback
                        invalid
                        className={
                          !!errors.name && touched.name ? 'd-block' : 'd-none'
                        }
                      >
                        {errors.name}
                      </CFormFeedback>
                    </CCol>
                    <CCol xs={12} md={6} className="mb-3">
                      <CFormLabel className="m-0 fw-bold" htmlFor="name">
                        Số phút trong 1 kỳ{' '}
                        <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        invalid={!!errors.minutes && touched.minutes}
                        id="minutes"
                        name="minutes"
                        value={values.minutes}
                        placeholder="Nhập trò chơi"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isDetail}
                      />
                      <CFormFeedback
                        invalid
                        className={
                          !!errors.minutes && touched.minutes
                            ? 'd-block'
                            : 'd-none'
                        }
                      >
                        {errors.minutes}
                      </CFormFeedback>
                    </CCol>

                    <CCol xs={12} md={6} className="mb-3">
                      <CFormLabel className="m-0 fw-bold" htmlFor="name">
                        Số bi trả kết quả: từ 1 đến 10{' '}
                        <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        invalid={!!errors.bi && touched.bi}
                        id="bi"
                        name="bi"
                        value={values.bi}
                        placeholder="Nhập số bi"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isDetail}
                      />
                      <CFormFeedback
                        invalid
                        className={
                          !!errors.name && touched.bi ? 'd-block' : 'd-none'
                        }
                      >
                        {errors.bi}
                      </CFormFeedback>
                    </CCol>

                    <CCol xs="6">
                      <CFormLabel className="mb-6 label-gray-700">
                        Chọn loại trò chơi
                      </CFormLabel>
                      <CFormSelect
                        className="form-height-44"
                        name="gameTypeId"
                        value={values.gameTypeId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={!!errors.gameTypeId && touched.gameTypeId}
                      >
                        {gameTypes.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </CFormSelect>
                      <CFormFeedback
                        invalid
                        className={
                          !!errors.gameTypeId && touched.gameTypeId
                            ? 'd-block'
                            : 'd-none'
                        }
                      >
                        {errors.gameTypeId}
                      </CFormFeedback>
                    </CCol>

                    <CCol xs="6">
                      <CFormLabel className="mb-6 label-gray-700">
                        Trạng thái
                      </CFormLabel>
                      <CFormSelect
                        className="form-height-44"
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={!!errors.status && touched.status}
                      >
                        {responseStatusArray.map((item, index) => (
                          <option key={index} value={item}>
                            {mapResponseStatus[item]}
                          </option>
                        ))}
                      </CFormSelect>
                      <CFormFeedback
                        invalid
                        className={
                          !!errors.status && touched.status
                            ? 'd-block'
                            : 'd-none'
                        }
                      >
                        {errors.status}
                      </CFormFeedback>
                    </CCol>

                    <CCol xs={12} md={6} className="mb-3">
                      <CFormLabel className="m-0 fw-bold" htmlFor="order">
                        Thứ tự
                        <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        invalid={!!errors.order && touched.order}
                        id="order"
                        name="order"
                        value={values.order}
                        placeholder="Nhập thứ tụ"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isDetail}
                      />
                      <CFormFeedback
                        invalid
                        className={
                          !!errors.order && touched.order ? 'd-block' : 'd-none'
                        }
                      >
                        {errors.order}
                      </CFormFeedback>
                    </CCol>
                  </CRow>
                  <CRow className="mb-3">
                    <CCol className="d-flex justify-content-end">
                      {/* <CButton
                        className="mx-2 custom-form-btn white-btn"
                        onClick={() => resetForm()}
                      >
                        Huỷ
                      </CButton> */}
                      <CLoadingButton
                        loading={loading}
                        className="text-white custom-form-btn ms-2 green-btn"
                        type="submit"
                      >
                        Lưu
                      </CLoadingButton>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CForm>
            )}
          </Formik>
          <CRow className={`${procedure ? '' : 'd-none'} mb-4`}>
            <CCol
              md={12}
              lg={6}
              className="mt-3"
              // style={{ background: '#f6f6f6' }}
            >
              <CCardHeader className="border border-bottom-0 d-flex flex-wrap justify-content-between">
                <CCardTitle className="my-1">Giải - Hạng</CCardTitle>
                <div>
                  <CButton
                    className={`custom-green-sm-btn text-white border-0 shadow-none me-2`}
                    onClick={handleClickUpdateStep}
                  >
                    <BsArrowDownUp className="mx-1" />{' '}
                    {visibleChangeItems ? 'Hủy sắp xếp' : 'Sắp xếp'}
                  </CButton>
                  <CButton
                    className={`${
                      visibleChangeItems ? 'bg-secondary' : ''
                    } custom-green-sm-btn text-white border-0 shadow-none`}
                    disabled={visibleChangeItems}
                    onClick={() => setCreateStep(true)}
                  >
                    <CIcon icon={cisPlus} size="sm" className="mx-1" /> Thêm mới
                  </CButton>
                </div>
              </CCardHeader>
              <CCardBody
                className="border bg-white rounded-bottom overflow-auto"
                style={{ maxHeight: '409px' }}
              >
                {/* <div className="mb-2 fw-semibold">Danh sách tiêu đề</div> */}
                {proceduceStepItems && proceduceStepItems.length > 0 ? (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="proceduce-drop">
                      {(droppableProvided) => (
                        <div
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                        >
                          {loadingStep ? (
                            <div className="d-flex justify-content-center align-items-center my-3">
                              <CSpinner color="light" />
                            </div>
                          ) : (
                            proceduceStepItems.map((item, index) => (
                              <Draggable
                                index={index}
                                draggableId={`${item.id}`}
                                key={`${item.id}`}
                              >
                                {(draggableProvided) => (
                                  <div
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                  >
                                    <ProcedureStepBox
                                      key={index}
                                      active={chosenStep?.id === item.id}
                                      index={index}
                                      stageItem={item}
                                      visible={visibleChangeItems}
                                      drag={draggableProvided}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))
                          )}
                          {droppableProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <div className="text-center">
                    Quy trình này chưa có tiêu đề nào !
                  </div>
                )}
              </CCardBody>
              <CCardFooter
                className={`${
                  visibleChangeItems ? '' : 'd-none'
                } bg-cms px-0 border-0`}
              >
                <CRow className="">
                  <CCol className="d-flex justify-content-end">
                    {/* <CButton
                      className="mx-2 custom-form-btn white-btn"
                      onClick={() => setProceduceStepItems(procedureStep)}
                    >
                      Huỷ
                    </CButton> */}
                    <CButton
                      className="text-white custom-form-btn ms-2 green-btn"
                      onClick={handleUpdateStep}
                    >
                      Lưu
                    </CButton>
                  </CCol>
                </CRow>
              </CCardFooter>
            </CCol>
            <CCol
              md={12}
              lg={6}
              className={`${chosenStep ? '' : 'd-none'} mt-3`}
              style={{ background: '#f6f6f6' }}
            >
              <CCardHeader className="border bg-white border-bottom-0 d-flex flex-wrap justify-content-between">
                <CCardTitle className="my-1">
                  <b>{chosenStep?.name}</b>
                </CCardTitle>
                <CButton
                  className={`custom-green-sm-btn text-white border-0 shadow-none`}
                  onClick={() => setCreateTask(true)}
                >
                  <CIcon icon={cisPlus} size="sm" className="mx-1" /> Thêm mới
                </CButton>
              </CCardHeader>
              <CCardBody
                className="border bg-white rounded-bottom"
                style={{ maxHeight: '409px', overflowY: 'scroll' }}
              >
                {/* <div className="mb-2 fw-semibold">Danh sách bị đặt</div> */}
                {procedureTask && procedureTask.length > 0 ? (
                  <>
                    {procedureTask.map((item, index) => (
                      <ProcedureTaskBox
                        key={index}
                        index={index}
                        taskItem={item}
                      />
                    ))}
                  </>
                ) : (
                  <div className="text-center">Tiêu đề này chưa có !</div>
                )}
              </CCardBody>
            </CCol>
            {createStep && id ? (
              <GameSidebarModal
                onClose={handleCloseCreateStepModal}
                gameId={Number(id)}
                visible={createStep}
              />
            ) : (
              ''
            )}
            {updateStep && chosenUpdateStep && id ? (
              <GameSidebarModal
                onClose={handleCloseUpdateStepModal}
                gameId={Number(id)}
                visible={updateStep}
                procedureStep={chosenUpdateStep}
              />
            ) : (
              ''
            )}

            {createTask && chosenStep ? (
              <BiDatCuocModal
                onClose={handleCloseCreateTaskModal}
                gameSidebarId={chosenStep.id}
                gameId={Number(id)}
                visible={createTask}
              />
            ) : (
              ''
            )}
            {updateTask && chosenUpdateTask && chosenStep ? (
              <BiDatCuocModal
                onClose={handleCloseUpdateTaskModal}
                gameSidebarId={chosenStep.id}
                gameId={Number(id)}
                visible={updateTask}
                procedureTask={chosenUpdateTask}
              />
            ) : (
              ''
            )}
            {chosenDeleteStep ? (
              <DeleteModal
                visible={isDeleteStep}
                setVisible={setIsDeleteStep}
                onDelete={handleRemoveStep}
                label={chosenDeleteStep.name}
                titleName={'tiêu đề'}
              />
            ) : (
              ''
            )}
            {chosenDeleteTask ? (
              <DeleteModal
                visible={isDeleteTask}
                setVisible={setIsDeleteTask}
                onDelete={handleRemoveTask}
                label={chosenDeleteTask.name}
                titleName={'nhiệm vụ'}
              />
            ) : (
              ''
            )}
          </CRow>
        </CCard>
      </div>
    </>
  )
}

export default GameNameUpdate
