import { IGame } from '@/shared/model/game.model'
import { IGameName } from '@/shared/model/gameName.model'
import axios from '../../../../shared/config/axios-interceptor'
import { useEffect, useState } from 'react'
import RenderImageF1 from '../../Wap/RenderImageF1'

interface IProp {
  game: IGameName | null
}

const MoThuongGanNhat = (props: IProp) => {
  const { game } = props

  const [listKetQua, setListKetQua] = useState<IGame[]>([])

  const getListKetQua = async () => {
    try {
      const response = await axios.get(
        '/draws?gameId=' +
          game?.id +
          '&status=closed&sortBy=id&sortOrder=DESC&limit=50',
      )
      setListKetQua(response.data.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getListKetQua()
  }, [game])

  return (
    <div className="item-right notify">
      <div className="header-item">
        <div className="w-100 justify-content-start px-3">
          Mở thưởng gần nhất
        </div>
      </div>
      <div className="content-item" style={{ height: 'auto' }}>
        <div className="tab1">
          <div className="header-class d-flex">
            <div className="w-25 text-center">Số kỳ</div>
            <div className="w-75 text-center">Kết quả mở thưởng</div>
          </div>

          <div className="list-content-sc" style={{ height: 1047 }}>
            {listKetQua?.map((item, index) => {
              return (
                <div className="content-class d-flex" key={index}>
                  <div
                    className="w-25 text-center"
                    style={{ color: '#89629a' }}
                  >
                    {item.id}
                  </div>
                  <div className="w-75">
                    <div className="d-flex item-bi-quay justify-content-between px-3 align-items-center">
                      {Array.from(
                        { length: game?.bi ?? 10 },
                        (_, i) => i + 1,
                      ).map((_, index) => {
                        const propertyName: any = `ball${index + 1}`
                        const ball = item?.[propertyName as keyof IGame] ?? 0

                        // Kiểm tra và xử lý giá trị ball nếu cần
                        let ballValue: React.ReactNode = null
                        if (
                          typeof ball === 'number' ||
                          typeof ball === 'string'
                        ) {
                          ballValue = ball
                        } else {
                          ballValue = '' // hoặc giá trị mặc định phù hợp
                        }

                        if(game?.group === 1) {
                            return (
                                <div key={index} className={'item item-' + ball}>
                                  {ballValue}
                                </div>
                              )
                        }

                        if(game?.group === 2) {
                            return (
                                <div key={index} className={'item item-' + ball}>
                                  {ballValue}
                                </div>
                              )
                        }

                        if(game?.group === 3) {
                            return (
                                <div key={index} className={'item ' + ball}>
                                  <RenderImageF1 ball={Number(ball)} width={19} height={19} />
                                </div>
                              )
                        }

                        return (
                          <div key={index} className={'item item-' + ball}>
                            {ballValue}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
export default MoThuongGanNhat
