import { IGame } from '@/shared/model/game.model'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

interface IData {
  // setDisableDatCuoc: any
  setLoad: any
  load: boolean
  ky: IGame | null
}
const KyQuay = (props: IData) => {
  const { setLoad, load, ky } = props

  const [timeInterval, setTimeInterval] = useState<any>({ minutes: "00", seconds: "00" })

  useEffect(() => {
    const gameEndTime = dayjs(ky?.endTime).add(2, 'seconds')

    const interval = setInterval(() => {
      const now = dayjs()
      const difference = gameEndTime.diff(now)

      if (difference > 0) {
        const hours = Math.floor(difference / (1000 * 60 * 60))
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60),
        )
        const seconds = Math.floor((difference % (1000 * 60)) / 1000)

        // if (minutes === 0 && seconds < 15) {
        //   setDisableDatCuoc(true)
        // } else {
        //   setDisableDatCuoc(false)
        // }

        let m = minutes < 10 ? '0' + minutes : minutes
        let s = seconds < 10 ? '0' + seconds : seconds
        // const formattedCountdown = `${m}:${s}`
        setTimeInterval({ minutes: m, seconds: s })
      } else {
        setLoad(!load)
        setTimeInterval({ minutes: "00", seconds: "00" })
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [ky?.endTime, load])

  return (
    <div className="keyquay d-flex justify-content-between align-items-center">
      <div style={{ color: '#4f3b70', fontSize: 13 }}>Kỳ {ky?.id}</div>
      <div className="d-flex time gap-2">
        <div>00</div>
        <div>{timeInterval.minutes}</div>
        <div>{timeInterval.seconds}</div>
      </div>
      <div style={{ color: '#a86a91', fontSize: 13 }}>Đang xác nhận</div>
    </div>
  )
}

export default KyQuay
