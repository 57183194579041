import { SVGProps } from "react"
const FilterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width || 16}
    height={props?.height || 16}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props?.fill}
      d="M1.332 3.067c0-.374 0-.56.073-.703a.667.667 0 0 1 .291-.291C1.839 2 2.026 2 2.399 2h11.2c.373 0 .56 0 .702.073a.667.667 0 0 1 .292.291c.072.143.072.33.072.703v.446c0 .18 0 .269-.021.352a.666.666 0 0 1-.095.207c-.049.07-.116.13-.252.247l-4.264 3.695c-.135.118-.203.176-.251.247a.667.667 0 0 0-.095.207c-.022.084-.022.173-.022.352v3.486c0 .13 0 .195-.02.252a.334.334 0 0 1-.089.13c-.044.04-.105.064-.226.113l-2.267.906c-.245.098-.367.147-.465.127a.334.334 0 0 1-.21-.142c-.056-.084-.056-.216-.056-.48V8.82c0-.179 0-.268-.022-.352a.666.666 0 0 0-.094-.207c-.049-.07-.117-.13-.252-.247L1.7 4.32c-.135-.117-.203-.176-.252-.247a.666.666 0 0 1-.094-.207c-.022-.083-.022-.173-.022-.352v-.446Z"
      opacity={0.12}
    />
    <path
      stroke={props?.stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.33}
      d="M1.332 3.067c0-.374 0-.56.073-.703a.667.667 0 0 1 .291-.291C1.839 2 2.026 2 2.399 2h11.2c.373 0 .56 0 .702.073a.667.667 0 0 1 .292.291c.072.143.072.33.072.703v.446c0 .18 0 .269-.021.352a.666.666 0 0 1-.095.207c-.049.07-.116.13-.252.247l-4.264 3.695c-.135.118-.203.176-.251.247a.667.667 0 0 0-.095.207c-.022.084-.022.173-.022.352v3.486c0 .13 0 .195-.02.252a.334.334 0 0 1-.089.13c-.044.04-.105.064-.226.113l-2.267.906c-.245.098-.367.147-.465.127a.334.334 0 0 1-.21-.142c-.056-.084-.056-.216-.056-.48V8.82c0-.179 0-.268-.022-.352a.666.666 0 0 0-.094-.207c-.049-.07-.117-.13-.252-.247L1.7 4.32c-.135-.117-.203-.176-.252-.247a.666.666 0 0 1-.094-.207c-.022-.083-.022-.173-.022-.352v-.446Z"
    />
  </svg>
)
export default FilterIcon
