export enum SystemRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
  AGENT = 'AGENT',
}

export const systemRoleArray: SystemRole[] = [
  SystemRole.USER, 
  SystemRole.AGENT
];

export const mapRoleToString: { [key in SystemRole]: string } = {
  [SystemRole.USER]: 'Thành viên',
  [SystemRole.ADMIN]: 'Quản trị viên',
  [SystemRole.AGENT]: 'Đại lý',
};

// Project Role

export enum RoleProjectEnum {
  MANAGER = 'MANAGER', // Quan ly
  EDITOR = 'EDITOR', // Bien tap vien
  STAFF = 'STAFF', // Nhan vien
}

export const roleProjectArray: RoleProjectEnum[] = [
  RoleProjectEnum.MANAGER,
  RoleProjectEnum.EDITOR,
  RoleProjectEnum.STAFF,
];

export const mapRoleProjectToString: { [key in RoleProjectEnum]: string } = {
  [RoleProjectEnum.MANAGER]: 'Quản lý',
  [RoleProjectEnum.EDITOR]: 'Biên tập viên',
  [RoleProjectEnum.STAFF]: 'Nhân viên',
};
