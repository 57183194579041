import Layout from './Layout'
import { useEffect, useState } from 'react'
import ArrowLeft from '@/components/shared/icons/web/ArrowLeft'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import axios from '../../../shared/config/axios-interceptor'
import { IHistory } from '@/shared/model/history.model'
import { RootState } from '@/reducers'
import { useSelector } from 'react-redux'
import {
  mapBiDatCuocGroup,
} from '@/shared/enumeration/ResponseStatus'
import { formatVND } from '@/shared/utils/ultils'

const HistoryOrder = () => {
  const { user } = useSelector((state: RootState) => state.authentication)

  const { navigate } = useRouter()

  const [historys, setHistorys] = useState<IHistory[] | []>([])

  const getHistory = async () => {
    try {
      const response = await axios.get(
        '/history?state=SHOW&sortOrder=DESC&sortBy=historyId&limit=30&userId=' +
          user?.id,
      )
      setHistorys(response.data.data)
    } catch (error) {
      setHistorys([])
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getHistory()
  }, [])

  const renderKQ = (item: any) => {
    if (item.isWin || item.isWin == 0) {
      if (item.isWin == 1) {
        return (
          <div className="text-success">
            Đã trúng thưởng: {formatVND(item.addMoney ?? 0)}
          </div>
        )
      }
      return <div className="text-danger">Không trúng thưởng</div>
    }
    return <div className="text-warning">Chưa mở thưởng</div>
  }

  return (
    <Layout>
      <div className="box-history-order-wap">
        <div className="box-header">
          <ArrowLeft
            style={{ position: 'absolute', left: 16 }}
            onClick={() => navigate(-1)}
          />

          <h4 className="text-white text-center mb-0">Lịch sử</h4>
        </div>

        <div className="box-history">
          <div className="box-list pb-4">
            {historys?.map((i: IHistory, index: number) => {
              return (
                <div className="item mt-2" key={index}>
                  <div className="header-item px-3 py-2 d-flex justify-content-between">
                    <div className="title">{i.biDatCuoc.game?.name}</div>
                    <div className="time">Kỳ: {i.KyId}</div>
                  </div>
                  <div className="content-item px-3 py-2">
                    <div className="item">
                      <div className="name mb-3">
                        {mapBiDatCuocGroup[i?.biDatCuoc?.group ?? 1]} -{' '}
                        {i.biDatCuoc.name}
                      </div>
                      <div className="rate mb-2 d-flex justify-content-between">
                        <div>Đặt cược: {formatVND(i?.money ?? 0)}</div>
                        {renderKQ(i)}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HistoryOrder
