import { useEffect, useState } from 'react'
import axios from '../../../shared/config/axios-interceptor'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import Pk10 from './game-layout/Pk10'
import { IGameName } from '@/shared/model/gameName.model'
import Esport from './game-layout/Esport'

const Game = () => {
  const { params } = useRouter()

  const [game, setGame] = useState<IGameName | null>(null)

  const getGame = async (id: number) => {
    try {
      const response = await axios.get('/game/' + id)
      setGame(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }


  useEffect(() => {
    getGame(Number(params?.id) ?? 1)
  }, [params?.id])

  if (!game) return <></>

  // EsportF1
  if (game.group === 3) {
    return <Esport game={game} />
  }

  return <Pk10  game={game} />

}

export default Game
