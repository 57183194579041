import { RootState } from '@/reducers'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { AppDispatch } from '@/store'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createEntity,
  getEntities,
  getEntity,
  updateEntity,
} from './configManagement.api'
import { CButton, CCol, CForm, CFormLabel, CRow } from '@coreui/react-pro'
import CustomFormikEditor from '@/components/shared/CustomEditor/CustomEditor'
import { Formik } from 'formik'
import { IConfig, INewConfig } from '@/shared/model/config.model'
import { fetching, resetEntity, selectEntityById } from './configManagement.reducer'
import { ToastSuccess } from '@/components/shared/toast/Toast'

const ConfigManagement = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { navigate, params } = useRouter()

  const { initialState } = useSelector(
    (state: RootState) => state.configReducer,
  )
  const {
    filterState,
    totalItems,
    totalPages,
    loading,
    updateEntitySuccess,
    deleteEntitySuccess,
  } = initialState

  useEffect(() => {
    if (updateEntitySuccess) {
      ToastSuccess('Cập nhật thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);

  useEffect(() => {
    dispatch(getEntities(filterState))
  }, [dispatch, filterState])

  const initialValues: IConfig = {
    id: 0,
    key: '',
    content: '',
  }

  const config = useSelector(selectEntityById('1'))

  console.log(config || initialValues);

  if(!config) return <></>
  
  return (
    <>
      <div className="tab-outlet-content">
        <Formik
          initialValues={config || initialValues}
          // validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(value) => {
            console.log(value)
            dispatch(fetching())
            if (config) {
              dispatch(updateEntity(value as IConfig))
            } else {
              value.key = 'nottification-first-login'
              dispatch(createEntity(value))
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => (
            <CForm onSubmit={handleSubmit} className="custom-form">
              <CRow>
                <CCol xs="12" md={3} lg={2}>
                  <CFormLabel htmlFor="content" style={{ fontSize: 16 }}>
                    Cấu hình thông báo
                  </CFormLabel>
                </CCol>

                <CCol xs="12" md={9} lg={10}>
                  <CustomFormikEditor
                    simpleMode
                    size="sm"
                    name="content"
                    value={values.content}
                    setFieldValue={setFieldValue}
                  />
                </CCol>

                <CCol xs="12">
                  <CButton type="submit" className="btn btn-primary">
                    Lưu cấu hình
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          )}
        </Formik>
      </div>
    </>
  )
}

export default ConfigManagement
