import { IGame } from "@/shared/model/game.model"
import { IGameName } from "@/shared/model/gameName.model"
import Pk10KetQua from "./Pk10KetQua"
import EsporttKetQua from "./EsportKetQua"

interface IProp {
    ketQuaKyTruoc: IGame | null
    game: IGameName | null,
    ky: IGame | null
}

const KetQua = (props: IProp) => {
    const { ketQuaKyTruoc, game, ky } = props

    if(game?.group === 1 ) return <Pk10KetQua ky={ky} ketQuaKyTruoc={ketQuaKyTruoc} game={game} />
    if(game?.group === 2 ) return <Pk10KetQua ky={ky} ketQuaKyTruoc={ketQuaKyTruoc} game={game} />
    if(game?.group === 3 ) return <EsporttKetQua ketQuaKyTruoc={ketQuaKyTruoc} game={game} />
    if(game?.group === 4 ) return <Pk10KetQua ky={ky} ketQuaKyTruoc={ketQuaKyTruoc} game={game} />
    if(game?.group === 5 ) return <Pk10KetQua ky={ky}  ketQuaKyTruoc={ketQuaKyTruoc} game={game} />
    if(game?.group === 6 ) return <Pk10KetQua ky={ky} ketQuaKyTruoc={ketQuaKyTruoc} game={game} />
    if(game?.group === 7 ) return <Pk10KetQua ky={ky} ketQuaKyTruoc={ketQuaKyTruoc} game={game} />
    if(game?.group === 8 ) return <Pk10KetQua ky={ky} ketQuaKyTruoc={ketQuaKyTruoc} game={game} />
    
    return <Pk10KetQua ky={ky} ketQuaKyTruoc={ketQuaKyTruoc} game={game} />
}
export default KetQua;