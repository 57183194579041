import Header from './Header'
import Sidebar from './Sidebar'


const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div className="container-web">
        <Header />
        <div className='content-header'>header</div>
        <div className='page-body-web'>
          <Sidebar />
          <div className='content-center'>
            {children}
          </div>
          <div className='content-right'>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout
