import f1_1 from '../../../assets/img/f1-1.png'
import f1_2 from '../../../assets/img/f1-2.png'
import f1_3 from '../../../assets/img/f1-3.png'
import f1_4 from '../../../assets/img/f1-4.png'
import f1_5 from '../../../assets/img/f1-5.png'
import f1_6 from '../../../assets/img/f1-6.png'
import f1_7 from '../../../assets/img/f1-7.png'
import f1_8 from '../../../assets/img/f1-8.png'
import f1_9 from '../../../assets/img/f1-9.png'
import f1_10 from '../../../assets/img/f1-10.png'

const RenderImageF1 = (props: { ball: number, width?: number; height?: number }) => {
    const { ball, width = 28, height = 28 } = props
    let a = f1_1
    if (ball == 1) {
      a = f1_1
    } else if (ball == 2) {
      a = f1_2
    } else if (ball == 3) {
      a = f1_3
    } else if (ball == 4) {
      a = f1_4
    } else if (ball == 5) {
      a = f1_5
    } else if (ball == 6) {
      a = f1_6
    } else if (ball == 7) {
      a = f1_7
    } else if (ball == 8) {
      a = f1_8
    } else if (ball == 9) {
      a = f1_9
    } else if (ball == 10) {
      a = f1_10
    }
    return <img width={width} height={height} src={a} />
}

export default RenderImageF1