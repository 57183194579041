import { NavLink } from 'react-router-dom'
import Header from './Header'
import Sidebar from './Sidebar'
import { CButton, CCol, CFormInput, CFormLabel, CRow } from '@coreui/react-pro'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '@/store'
import { ToastError, ToastSuccess } from '@/components/shared/toast/Toast'
import axios from '../../../shared/config/axios-interceptor'
import { getProfile } from '../auth/auth.api'
import { RootState } from '@/reducers'
import Nav from './Nav'

const RutTien = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.authentication)

  const [money, setMoney] = useState(0)
  const [password, settPassword] = useState('')

  const submitHanlder = async () => {
    if (!user?.cardName || !user?.cardNumber || !user?.bankName) {
      ToastError('Vui lòng liên kết ngân hàng trước khi rút tiền')
      return
    }

    if (money < 30) {
      ToastError('Số tiền rút phải từ 30')
      return
    }

    if (Number(money) > Number(user?.money)) {
      ToastError('Số tiền không hợp lệ')
      return
    }


    if (user?.money - money < 0) {
      ToastError('Số tiền không hợp lệ')
      return
    }

    if (!user?.passsWithdraw) {
      ToastError('Vui lòng thiết lập mật khẩu rút tiền trước')
      return
    }

    if (user?.passsWithdraw !== password) {
      ToastError('Sai mật khẩu rút tiền')
      return
    }

    try {
      const response = await axios.post('/transaction', {
        money,
        type: 2,
      })
      setMoney(0)
      settPassword('')
      ToastSuccess('Hệ thống đang xử lý. Vui lòng chờ trong ít phút')
      dispatch(getProfile())
    } catch (error) {
      console.error('Error:', error)
      dispatch(getProfile())
    }
  }

  return (
    <div className="container-web page-rut-tien-web">
      <Header />
      <div className="content-header d-flex gap-3">
        <Nav />

        <div className="header-bottom m-0 w-100">
          <div
            className="top d-flex px-3  align-items-center fw-bold"
            style={{ height: 45 }}
          >
            Thông tin rút tiền
          </div>

          <div className="p-3 d-flex gap-3">
            <div style={{ width: 600 }}>
              <CRow className="mt-3">
                <CCol xs={3}>
                  <CFormLabel>
                    <span style={{ color: 'red' }}>*</span> Số tiền rút
                  </CFormLabel>
                </CCol>
                <CCol xs={9}>
                  <CFormInput
                    type="number"
                    value={money}
                    onChange={(e: any) => setMoney(e.target.value)}
                  />
                </CCol>
              </CRow>

              <CRow className="mt-3">
                <CCol xs={3}>
                  <CFormLabel>
                    <span style={{ color: 'red' }}>*</span> Mật khẩu rút tiền
                  </CFormLabel>
                </CCol>
                <CCol xs={9}>
                  <CFormInput
                    type="password"
                    value={password}
                    onChange={(e: any) => settPassword(e.target.value)}
                  />
                </CCol>
              </CRow>

              <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
                <div>
                  {!(user?.cardName && user?.cardNumber && user?.bankName) && (
                    <div>
                      Bạn chưa liên kết ngân hàng?{' '}
                      <NavLink to={'/the-ngan-hang'}>
                        Liên kết ngân hàng ngay
                      </NavLink>
                    </div>
                  )}
                </div>
                <CButton
                  onClick={submitHanlder}
                  className="px-4"
                  style={{ background: '#f49400' }}
                >
                  Xác nhận
                </CButton>
              </div>

              <p style={{fontSize: 14, color: "red"}} className="mt-4">Thông báo: Quý khách lưu ý thời gian rút tiền từ khung thời gian 9 giờ 30 phút đến 23 giờ 00</p>

            </div>
          </div>
        </div>
      </div>
      <div className="page-body-web">
        {/* <Sidebar /> */}
      </div>
    </div>
  )
}

export default RutTien
