import { SVGProps } from "react"
const SendIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
  width={props?.width || 20}
  height={props?.height || 20}
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.75036 9.99982H4.16702M4.09648 10.2427L2.15071 16.055C1.99785 16.5116 1.92142 16.7399 1.97627 16.8805C2.0239 17.0026 2.1262 17.0952 2.25244 17.1304C2.3978 17.171 2.61736 17.0722 3.05647 16.8746L16.9827 10.6078C17.4113 10.4149 17.6256 10.3185 17.6918 10.1845C17.7494 10.0681 17.7494 9.93155 17.6918 9.81516C17.6256 9.68119 17.4113 9.58475 16.9827 9.39188L3.05161 3.12293C2.61383 2.92593 2.39493 2.82742 2.24971 2.86783C2.1236 2.90292 2.0213 2.99524 1.97351 3.11711C1.91847 3.25744 1.99408 3.48526 2.14531 3.94088L4.09702 9.8211C4.12299 9.89935 4.13598 9.93848 4.14111 9.9785C4.14565 10.014 4.14561 10.05 4.14097 10.0855C4.13574 10.1255 4.12265 10.1646 4.09648 10.2427Z"
      stroke={props?.stroke}
      strokeWidth={1.33333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SendIcon
