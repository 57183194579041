import { IGameName } from '@/shared/model/gameName.model'
import { NavLink } from 'react-router-dom'
import KyQuay from './KyQuay'
import { useEffect, useState } from 'react'
import { IGame } from '@/shared/model/game.model'
import axios from '../../../shared/config/axios-interceptor'
import Pk10KetQua from './bi-ket-qua/Pk10KetQua'
import KetQua from './bi-ket-qua/KetQua'

interface IBoxKyQuayHeader {
  load: boolean
  ky: IGame | null
  setDisableDatCuoc: any
  setLoad: any
  game: IGameName | null
}

const BoxKyQuayHeader = (props: IBoxKyQuayHeader) => {
  const { game, load, ky, setDisableDatCuoc, setLoad } = props

  const [ketQuaKyTruoc, setKetQuaKyTruoc] = useState<IGame | null>(null)

  const getKetQuaKyTruoc = async () => {
    try {
      const response = await axios.get(
        '/draws/get-result-ket-qua-ky-truoc/' + ( game?.id ?? 0),
      )
      setKetQuaKyTruoc(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getKetQuaKyTruoc()
  }, [game])

  return (
    <div className="content-header justify-content-between">
      <div
        className="d-flex gap-2 align-items-center ms-2"
        style={{ width: 220 }}
      >
        <img
          width={60}
          height={60}
          src={
            game?.imageDesktop
              ? game?.imageDesktop
              : 'https://www.tg25.vip/web/img/icon-lottery_48-vi.9c9e7742.png'
          }
        />
        <h3 style={{ fontSize: 22, color: '#6d4f84' }} className="fw-bold m-0">
          {game?.name}
        </h3>
      </div>

      <KyQuay
        load={load}
        ky={ky}
        setDisableDatCuoc={setDisableDatCuoc}
        setLoad={setLoad}
      />

      <KetQua ketQuaKyTruoc={ketQuaKyTruoc} game={game} ky={ky} />

      <div className="action-btn mx-3">
        <div className="item" style={{ marginBottom: 20 }}>
          <NavLink
            className="text-decoration-none text-white"
            to={'/lich-su-dat-cuoc'}
          >
            Lịch sử tham gia
          </NavLink>
        </div>
        <div className="item">
          <NavLink
            className="text-decoration-none text-white"
            to={'/lich-su-dat-cuoc'}
          >
            Lịch sử giao dịch
          </NavLink>
        </div>
      </div>
    </div>
  )
}
export default BoxKyQuayHeader
