import * as React from 'react';
import { SVGProps } from 'react';
const LockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width || 20}
    height={props?.height || 20}
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.12"
      d="M3.33203 12.3333C3.33203 10.9332 3.33203 10.2331 3.60451 9.69834C3.8442 9.22793 4.22665 8.84548 4.69705 8.6058C5.23183 8.33331 5.9319 8.33331 7.33203 8.33331H12.6654C14.0655 8.33331 14.7656 8.33331 15.3003 8.6058C15.7707 8.84548 16.1532 9.22793 16.3929 9.69834C16.6654 10.2331 16.6654 10.9332 16.6654 12.3333V13.5C16.6654 14.9001 16.6654 15.6002 16.3929 16.135C16.1532 16.6054 15.7707 16.9878 15.3003 17.2275C14.7656 17.5 14.0655 17.5 12.6654 17.5H7.33203C5.9319 17.5 5.23183 17.5 4.69705 17.2275C4.22665 16.9878 3.8442 16.6054 3.60451 16.135C3.33203 15.6002 3.33203 14.9001 3.33203 13.5V12.3333Z"
      fill={props?.fill}
    />
    <path
      d="M14.1654 8.33333V6.66667C14.1654 4.36548 12.2999 2.5 9.9987 2.5C7.69751 2.5 5.83203 4.36548 5.83203 6.66667V8.33333M9.9987 12.0833V13.75M7.33203 17.5H12.6654C14.0655 17.5 14.7656 17.5 15.3003 17.2275C15.7707 16.9878 16.1532 16.6054 16.3929 16.135C16.6654 15.6002 16.6654 14.9001 16.6654 13.5V12.3333C16.6654 10.9332 16.6654 10.2331 16.3929 9.69836C16.1532 9.22795 15.7707 8.8455 15.3003 8.60582C14.7656 8.33333 14.0655 8.33333 12.6654 8.33333H7.33203C5.9319 8.33333 5.23183 8.33333 4.69705 8.60582C4.22665 8.8455 3.8442 9.22795 3.60451 9.69836C3.33203 10.2331 3.33203 10.9332 3.33203 12.3333V13.5C3.33203 14.9001 3.33203 15.6002 3.60451 16.135C3.8442 16.6054 4.22665 16.9878 4.69705 17.2275C5.23183 17.5 5.9319 17.5 7.33203 17.5Z"
      stroke={props?.stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default LockIcon;
