export enum HistoryState {
    SHOW = 'SHOW',
    HIDE = 'HIDE',
    // DELETED = 'DELETED',
  }
  
  export const responseHistoryArray: HistoryState[] = [
    HistoryState.SHOW,
    HistoryState.HIDE,
    // HistoryState.DELETED,
  ];
  
  export const mapHistoryState: { [key in HistoryState]: string } = {
    [HistoryState.HIDE]: 'Ẩn',
    [HistoryState.SHOW]: 'Hiện',
    // [ResponseStatus.DELETED]: 'Đã xóa',
  };