import { cilChevronLeftAlt } from '@coreui/icons-pro';
import CIcon from '@coreui/icons-react';
import { CButton, CCardHeader, CCardTitle } from '@coreui/react-pro';
import { useRouter } from '../../../shared/utils/hooks/useRouter';

interface ICustomDetailBackward {
  redirect?: string;
  redirectText: string;
  title: string;
}

const CustomDetailBackward = (props: ICustomDetailBackward) => {
  const { redirect, title, redirectText } = props;
  const { redirectView } = useRouter();

  return (
    <CCardHeader className="bg-cms border-0 px-0">
      <CButton className={`custom-goback-btn `} onClick={redirectView(redirect ?? '')}>
        <CIcon icon={cilChevronLeftAlt} size="sm" className="mx-1" /> {redirectText}
      </CButton>
      <CCardTitle className="my-3">{title}</CCardTitle>
    </CCardHeader>
  );
};

export default CustomDetailBackward;
