import { SVGProps } from 'react';
const BellSidebarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width || 16}
    height={props?.height || 16}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={props?.fill}
      d="M12.002 5.333a4 4 0 1 0-8 0c0 2.06-.52 3.47-1.1 4.404-.49.787-.735 1.18-.726 1.29.01.122.036.168.134.24.088.066.487.066 1.284.066h8.815c.798 0 1.196 0 1.285-.065.098-.073.124-.12.134-.24.009-.11-.236-.504-.726-1.291-.58-.933-1.1-2.344-1.1-4.404Z"
      opacity={0.12}
    />
    <path
      stroke={props?.stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
      d="M6.238 14c.47.415 1.088.667 1.764.667.676 0 1.294-.252 1.764-.667m2.236-8.667a4 4 0 1 0-8 0c0 2.06-.52 3.47-1.1 4.404-.49.787-.735 1.18-.726 1.29.01.122.036.168.134.24.088.066.487.066 1.284.066h8.815c.798 0 1.196 0 1.285-.065.098-.073.124-.12.134-.24.009-.11-.236-.504-.726-1.291-.58-.933-1.1-2.344-1.1-4.404Z"
    />
  </svg>
);
export default BellSidebarIcon;
