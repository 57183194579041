import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocaled from 'dayjs/plugin/updateLocale';

// plugins

export const dayjsSetup = () => {
  /**
   * Setup dayjs global locale
   */
  dayjs.extend(relativeTime);
  dayjs.extend(updateLocaled);
  dayjs.locale('vi');
  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'trong %s nữa',
      past: '%s trước',
      s: 'vài giây',
      m: '1 phút',
      mm: '%d phút',
      h: '1 giờ',
      hh: '%d giờ',
      d: '1 ngày',
      dd: '%d ngày',
      M: '1 tháng',
      MM: '%d tháng',
      y: '1 năm',
      yy: '%d năm',
    },
  });
};
