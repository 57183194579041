import {
  CButton,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from '@coreui/react-pro'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import {
  ResponseStatusUser,
  ResponseVip,
  mapResponseStatusUser,
  mapResponseVip,
  responseStatusUserArray,
  responseVipArray,
} from '../../../shared/enumeration/ResponseStatus'
import { ISelectValue } from '../../../shared/shared-interfaces'
import SModal from '../../shared/Modal/SModal'
import EyeIcon from '../../shared/icons/EyeIcon'
import EyeOffIcon from '../../shared/icons/EyeOffIcon'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../reducers'
import { INewUser, IUser } from '../../../shared/model/user.model'
import { AppDispatch } from '../../../store'
import { ToastSuccess } from '../../shared/toast/Toast'
import { createEntity, updateEntity } from './usersManagement.api'
import { fetching, resetEntity } from './usersManagement.reducer'
import {
  mapRoleToString,
  SystemRole,
  systemRoleArray,
} from '@/shared/enumeration/role'
import { IBank } from '@/shared/model/bank.model'
import { bankSelectors } from '../BankManagement/banksManagement.reducer'
import { getEntities as getEntitiesBank } from '../BankManagement/banksManagement.api'
import Select, { SingleValue, components } from 'react-select'

interface IUserUpdateProps {
  visible: boolean
  setVisible: (visible: boolean) => void
  userObj?: IUser
}

export const CustomOption = (props: any) => {
  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <img
          src={props.data.image}
          alt={props.data.label}
          style={{ width: 100, height: 30, marginRight: 10 }}
        />
        <div>{props.data.label}</div>
      </div>
    </components.Option>
  )
}

export const CustomSingleValue = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <div className="d-flex align-items-center">
        <img
          src={props.data.image}
          alt={props.data.label}
          style={{ width: 100, height: 30, marginRight: 10 }}
        />
        <div>{props.data.label}</div>
      </div>
    </components.SingleValue>
  )
}

const UserUpdate = (props: IUserUpdateProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { initialState } = useSelector((state: RootState) => state.usersReducer)
  const { updateEntitySuccess } = initialState
  const { visible, setVisible, userObj } = props
  const handleOnClose = () => {
    setVisible(false)
  }

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(
    false,
  )
  const banks = useSelector(bankSelectors.selectAll)

  useEffect(() => {
    if (updateEntitySuccess) {
      setVisible(false)
      ToastSuccess(
        userObj ? 'Cập nhật tài khoản thành công' : 'Tạo tài khoản thành công',
      )
      dispatch(resetEntity())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess])

  const initialValues: INewUser = {
    fullName: '',
    username: '',
    email: '',
    money: 0,
    password: '',
    confirmPassword: '',
    permissionGroupId: '',
    cardName: '',
    cardNumber: '',
    bankName: 0,
    status: ResponseStatusUser.ACTIVE,
    vip: ResponseVip.VIP1,
    role: SystemRole.USER,
    passsWithdraw: '',
  }

  useEffect(() => {
    dispatch(getEntitiesBank({ page: 1, limit: 100 }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .trim()
      .required('Không được để trống')
      .matches(/^\S+$/, 'Tên đăng nhập không được chứa khoảng trống')
      .matches(
        /^\w+$/,
        `Tên đăng nhập không được viết dấu hoặc chứa ký tự đặc biệt`,
      )
      .max(255, 'Số ký tự tối đa là 255 ký tự'),
    money: Yup.number().required('Không được để trống').nullable(),
    status: Yup.string().trim().required('Không được để trống'),
  })

  const returnBankItem = (items: IBank[], selectBank?: IBank | null) => {
    return items.map((item) => {
      return {
        value: item.bankId,
        label: item.name,
        image: item.avatar,
      }
    })
  }

  const returnOption = (
    optionValue: number | null | undefined,
    array: ISelectValue<string>[],
  ) => {
    if (!optionValue) return null
    if (!array.length) return null
    return array.find(({ value }) => Number(value) === Number(optionValue))
  }

  const customBankItems: any = returnBankItem(banks)

  return (
    <SModal
      visible={visible}
      onClose={handleOnClose}
      backdrop="static"
      className="custom-modal"
      size="lg"
    >
      <Formik
        initialValues={userObj || initialValues}
        validationSchema={validationSchema}
        // validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(value) => {
          dispatch(fetching())
          if (userObj) {
            dispatch(updateEntity(value as IUser))
          } else {
            dispatch(createEntity(value))
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <CForm onSubmit={handleSubmit} className="custom-form">
            <>
              <CModalHeader>
                {userObj ? 'Chỉnh sửa tài khoản' : 'Tạo tài khoản'}
              </CModalHeader>
              <CModalBody>
                <CRow className="g-4">
                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">
                      Tên tài khoản
                    </CFormLabel>

                    <CFormInput
                      className="form-height-44"
                      name="username"
                      autoComplete="off"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.username && touched.username}
                    />
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.username && touched.username
                          ? 'd-block'
                          : 'd-none'
                      }
                    >
                      {errors.username}
                    </CFormFeedback>
                  </CCol>
                  {/* <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">Họ và tên</CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="fullName"
                      value={values.fullName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.fullName && touched.fullName}
                    />
                    <CFormFeedback invalid className={!!errors.fullName && touched.fullName ? 'd-block' : 'd-none'}>
                      {errors.fullName}
                    </CFormFeedback>
                  </CCol> */}

                  <CCol xs={12}>
                    <CRow>
                      <CCol xs="6">
                        <CFormLabel className="mb-6 label-gray-700">
                          Số dư:{' '}
                          <strong className="text-danger">
                            {new Intl.NumberFormat('vi-VN').format(
                              values?.money || 0,
                            )}
                          </strong>
                        </CFormLabel>
                        <CFormInput
                          className="form-height-44"
                          name="money"
                          value={values.money}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={!!errors.money && touched.money}
                        />
                        <CFormFeedback
                          invalid
                          className={
                            !!errors.money && touched.money
                              ? 'd-block'
                              : 'd-none'
                          }
                        >
                          {errors.money}
                        </CFormFeedback>
                      </CCol>

                      <CCol xs="6">
                        <CFormLabel className="mb-6 label-gray-700">
                          VIP
                        </CFormLabel>
                        <CFormSelect
                          className="form-height-44"
                          name="vip"
                          value={values.vip}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={!!errors.vip && touched.vip}
                        >
                          {responseVipArray.map((item, index) => (
                            <option key={index} value={item}>
                              {mapResponseVip[item]}
                            </option>
                          ))}
                        </CFormSelect>
                        <CFormFeedback
                          invalid
                          className={
                            !!errors.vip && touched.vip ? 'd-block' : 'd-none'
                          }
                        >
                          {errors.vip}
                        </CFormFeedback>
                      </CCol>
                    </CRow>
                  </CCol>

                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">
                      Tên ngân hàng
                    </CFormLabel>
                    {/* <CFormInput
                      className="form-height-44"
                      name="bankName"
                      value={values.bankName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.bankName && touched.bankName}
                    /> */}

                    <Select
                      // menuIsOpen={true}
                      defaultValue={null}
                      className="custom-select form-height-44"
                      classNamePrefix="react-select"
                      value={
                        returnOption(
                          values.bankName as any,
                          customBankItems,
                        ) as any
                      }
                      onChange={(
                        newValue: SingleValue<ISelectValue<string>>,
                      ) => {
                        setFieldValue(`bankName`, newValue?.value || '')
                      }}
                      noOptionsMessage={() => <>Không có ngân hàng nào</>}
                      id={'bankName'}
                      options={customBankItems}
                      placeholder={'Chọn Ngân Hàng'}
                      isClearable={true}
                      closeMenuOnSelect={true}
                      menuPosition={'absolute'}
                      name="bankName"
                      components={{
                        Option: CustomOption,
                        SingleValue: CustomSingleValue,
                      }}
                    />

                    <CFormFeedback
                      invalid
                      className={
                        !!errors.bankName && touched.bankName
                          ? 'd-block'
                          : 'd-none'
                      }
                    >
                      {errors.bankName}
                    </CFormFeedback>
                  </CCol>

                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">
                      Tên tài khoản ngân hàng
                    </CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="cardName"
                      value={values.cardName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.cardName && touched.cardName}
                    />
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.cardName && touched.cardName
                          ? 'd-block'
                          : 'd-none'
                      }
                    >
                      {errors.cardName}
                    </CFormFeedback>
                  </CCol>

                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">
                      Số tài khoản ngân hàng
                    </CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="cardNumber"
                      value={values.cardNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.money && touched.cardNumber}
                    />
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.cardNumber && touched.cardNumber
                          ? 'd-block'
                          : 'd-none'
                      }
                    >
                      {errors.cardNumber}
                    </CFormFeedback>
                  </CCol>

                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">
                      Mật khẩu rút tiền
                    </CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="passsWithdraw"
                      value={values.passsWithdraw}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.money && touched.passsWithdraw}
                    />
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.passsWithdraw && touched.passsWithdraw
                          ? 'd-block'
                          : 'd-none'
                      }
                    >
                      {errors.passsWithdraw}
                    </CFormFeedback>
                  </CCol>

                  {/* <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">Email</CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.email && touched.email}
                    />
                    <CFormFeedback invalid className={!!errors.email && touched.email ? 'd-block' : 'd-none'}>
                      {errors.email}
                    </CFormFeedback>
                  </CCol> */}

                  {/* {!userObj ? ( */}
                  <CCol xs={12}>
                    <CRow>
                      <CCol xs="12" md="6">
                        <CFormLabel className="mb-6 label-gray-700">
                          Mật khẩu
                        </CFormLabel>

                        <CInputGroup className="input-end-group">
                          <CFormInput
                            className="form-height-44"
                            name="password"
                            autoComplete="off"
                            type={passwordVisible ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={!!errors.password && touched.password}
                          />{' '}
                          <CInputGroupText
                            id="search-addon"
                            className="cursor-pointer form-height-44"
                            onClick={(e) => {
                              e.stopPropagation()
                              setPasswordVisible(!passwordVisible)
                            }}
                          >
                            {passwordVisible ? <EyeOffIcon /> : <EyeIcon />}
                          </CInputGroupText>
                        </CInputGroup>
                        <CFormFeedback
                          invalid
                          className={
                            !!errors.password && touched.password
                              ? 'd-block'
                              : 'd-none'
                          }
                        >
                          {errors.password}
                        </CFormFeedback>
                      </CCol>

                      <CCol xs="12" md="6">
                        <CFormLabel className="mb-6 label-gray-700">
                          Xác nhận mật khẩu
                        </CFormLabel>
                        <CInputGroup className="input-end-group">
                          <CFormInput
                            className="form-height-44"
                            name="confirmPassword"
                            type={confirmPasswordVisible ? 'text' : 'password'}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={
                              !!errors.confirmPassword &&
                              touched.confirmPassword
                            }
                          />
                          <CInputGroupText
                            id="search-addon"
                            className="cursor-pointer form-height-44"
                            onClick={(e) => {
                              e.stopPropagation()
                              setConfirmPasswordVisible(!confirmPasswordVisible)
                            }}
                          >
                            {confirmPasswordVisible ? (
                              <EyeOffIcon />
                            ) : (
                              <EyeIcon />
                            )}
                          </CInputGroupText>
                        </CInputGroup>
                        <CFormFeedback
                          invalid
                          className={
                            !!errors.confirmPassword && touched.confirmPassword
                              ? 'd-block'
                              : 'd-none'
                          }
                        >
                          {errors.confirmPassword}
                        </CFormFeedback>
                      </CCol>
                    </CRow>
                  </CCol>
                  {/* ) : (
                    ''
                  )} */}

                  {/* <CCol xs="12" onClick={(e) => e.stopPropagation()}>
                    <CFormLabel className="mb-6 label-gray-700">Quyền</CFormLabel>
                    <Select
                      // menuIsOpen={true}
                      defaultValue={null}
                      className="custom-select form-height-44"
                      classNamePrefix="react-select"
                      value={returnOption(Number(values.permissionGroupId), customPermissionItems) as any}
                      onChange={(newValue: SingleValue<ISelectValue<string>>) => {
                        if (!newValue) {
                          setPermissionFilter({ page: 1, limit: 20 });
                        }
                        setFieldValue(`permissionGroupId`, Number(newValue?.value) || '');
                      }}
                      noOptionsMessage={() => <>Không có Nhóm quyền nào</>}
                      onMenuScrollToBottom={() => {
                        setPermissionFilter({ ...permissionFilter, limit: permissionFilter.limit + 20 });
                      }}
                      onInputChange={(newValue: string) => {
                        setPermissionFilter({ ...permissionFilter, key: newValue });
                      }}
                      id={'permissionGroupId'}
                      options={customPermissionItems}
                      placeholder={'Chọn Nhóm quyền'}
                      isClearable={true}
                      closeMenuOnSelect={true}
                      menuPosition={'absolute'}
                      // menuPortalTarget={document.body}
                    />
                    <CFormFeedback
                      invalid
                      className={!!errors.permissionGroupId && touched.permissionGroupId ? 'd-block' : 'd-none'}
                    >
                      {errors.permissionGroupId}
                    </CFormFeedback>
                  </CCol> */}

                  <CCol xs="12">
                    <CRow>
                      <CCol xs="6">
                        <CFormLabel className="mb-6 label-gray-700">
                          Trạng thái
                        </CFormLabel>
                        <CFormSelect
                          className="form-height-44"
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={!!errors.status && touched.status}
                        >
                          {responseStatusUserArray.map((item, index) => (
                            <option key={index} value={item}>
                              {mapResponseStatusUser[item]}
                            </option>
                          ))}
                        </CFormSelect>
                        <CFormFeedback
                          invalid
                          className={
                            !!errors.status && touched.status
                              ? 'd-block'
                              : 'd-none'
                          }
                        >
                          {errors.status}
                        </CFormFeedback>
                      </CCol>
                      <CCol xs="6">
                        <CFormLabel className="mb-6 label-gray-700">
                          Loại tài khoản
                        </CFormLabel>
                        <CFormSelect
                          className="form-height-44"
                          name="role"
                          value={values.role}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={!!errors.role && touched.role}
                        >
                          {systemRoleArray.map((item, index) => (
                            <option key={index} value={item}>
                              {mapRoleToString[item]}
                            </option>
                          ))}
                        </CFormSelect>
                        <CFormFeedback
                          invalid
                          className={
                            !!errors.role && touched.role ? 'd-block' : 'd-none'
                          }
                        >
                          {errors.role}
                        </CFormFeedback>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CModalBody>
              <CModalFooter className="d-flex justify-content-end">
                <CButton
                  className="btn-custom minw-120 variant-gray-300"
                  type="button"
                  onClick={() => {
                    resetForm()
                    setVisible(false)
                  }}
                >
                  Huỷ
                </CButton>
                <CButton
                  className="btn-custom minw-120 primary-500"
                  type="submit"
                >
                  Lưu
                </CButton>
              </CModalFooter>
            </>
          </CForm>
        )}
      </Formik>
    </SModal>
  )
}

export default UserUpdate
